import React, { Component } from "react";
import { Dialog } from "primereact/dialog";
import { ListBox } from "primereact/listbox";
import { Button } from "primereact/button";
import PropTypes from "prop-types";

class SelectClindocModal extends Component {
  state = {};

  constructor(props) {
    super(props);

    this.document = null;

    this.handleSelectionChange = this.handleSelectionChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  static propTypes = {
    clindocList: PropTypes.array, //the list
    handleSave: PropTypes.func, //the function
  };

  itemTemplate(option) {
    return (
      <div>
        <span className="p-text-bold">{option.name}</span> |{" "}
        <span style={{ fontSize: "0.875rem" }}>{option.description}</span>
      </div>
    );
  }

  handleSelectionChange(event) {
    //console.log(event.value);
    this.setState({ document: event.value });
  }

  handleSave() {
    //hide modal
    this.props.onHide();

    //trigger reduc action
    let { document } = this.state;
    if (document !== null) {
      //console.log(document);
      this.props.handleSave(document);
      this.setState({ document: null });
    }
  }

  onCancel() {
    this.setState({ document: null });
    this.props.onHide();
  }

  renderFooter(name) {
    return (
      <div>
        <Button
          label="Save"
          icon="pi pi-check"
          onClick={this.handleSave}
          autoFocus
        />
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={this.onCancel}
          className="p-button-text"
        />
      </div>
    );
  }

  renderlist = () => {
    return (
      <React.Fragment>
        <ListBox
          filter={true}
          filterby="name,description"
          optionLabel="description"
          value={this.state.city}
          options={this.props.clindocList}
          onChange={(e) => this.handleSelectionChange(e)}
          listStyle={{ maxHeight: "25wm" }}
          itemTemplate={this.itemTemplate}
        />
      </React.Fragment>
    );
  };
  renderEmptyMessage = () => {
    return (
      <React.Fragment>
        <h4>
          You have no documents associated with your account at the moment.
        </h4>
        <p>Import a document from the gallery or create one in the editor.</p>
      </React.Fragment>
    );
  };

  render() {
    return (
      <Dialog
        header="Choose a document template:"
        visible={this.props.visible}
        footer={this.renderFooter("displayConfirmation")}
        style={{ width: "50vw" }}
        onHide={this.onCancel}
      >
        <div className="confirmation-content">
          {this.props.clindocList.length > 0
            ? this.renderlist()
            : this.renderEmptyMessage()}
        </div>
      </Dialog>
    );
  }
}

export default SelectClindocModal;
