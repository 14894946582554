import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Rating } from "primereact/rating";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

import GalleryClinDocPreviewPanel from "./components/gallery/galleryClinDocPreviewPanel";
import GalleryClinDocFormPanel from "./components/gallery/galleryClinDocFormPanel";

import * as clinDocActions from "./actions/clindoc";
import * as helper from "./components/helperFunctions";
import { LANGUAGES } from "./config";
import { connect } from "react-redux";

import {
  fetchGalleryClindocDetails,
  fetchGalleryClindocs,
  createNewGalleryClindoc,
  deleteGalleryClinDoc,
} from "./actions/gallery";

class Gallery extends Component {
  state = {
    showDeleteConfirmation: false,
    showImportConfirmation: false,
    showAddConfirmation: false,
    showPreview: false,
    showEditor: false,
    selectedLanguages: [""],
    globalFilter: null,
    languageOptions: [],
  };

  constructor(props) {
    super(props);

    for (const [key, value] of Object.entries(LANGUAGES)) {
      this.state.languageOptions.push({ value: key, label: value.name });
    }

    this.state.selectedLanguages = [this.props.user.profile.language || ""];

    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.editClindoc = this.editClindoc.bind(this);
    this.newClindoc = this.newClindoc.bind(this);
    this.previewClindoc = this.previewClindoc.bind(this);
    this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
    this.languageBodyTemplate = this.languageBodyTemplate.bind(this);
    this.onLanguageFilterChange = this.onLanguageFilterChange.bind(this);
    this.onRatingFilterChange = this.onRatingFilterChange.bind(this);
  }

  componentDidMount() {
    this.props.fetchClinDocs();
    this.props.fetchBaseClindocs();

    //set filter based on profile
    let languageList =
      this.props.user.profile.language !== ""
        ? [this.props.user.profile.language]
        : [];
    this.onLanguageFilterChange(languageList);
  }

  //button logic

  editClindoc(document) {
    this.props.updateClinDocDetails(document.id);
    this.setState({ showEditor: true });
  }

  newClindoc() {
    //this.props.updateClinDocDetails(document.id);
    this.props.createNewClindoc();
    this.setState({ showEditor: true });
  }

  previewClindoc(document) {
    this.props.updateClinDocDetails(document.id);
    this.setState({ showPreview: true });
  }

  deleteClindoc(document) {
    this.props.updateClinDocDetails(document.id);
    this.setState({ showDeleteConfirmation: true });
  }
  //templates

  ratingBodyTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Activity</span>
        <Rating
          value={Math.ceil(rowData.average_rating)}
          starts={5}
          readonly
          cancel={false}
          //onChange={(e) => this.setState({ value: e.value })}
        />
      </React.Fragment>
    );
  }
  leftToolbarTemplate() {
    return (
      <React.Fragment>
        <Button
          label="Add a new document"
          icon="pi pi-plus"
          className="p-button-success p-mr-2"
          onClick={() => this.newClindoc()}
        />
      </React.Fragment>
    );
  }

  rightToolbarTemplate() {
    return (
      <React.Fragment>
        <Button
          label="Add to App..."
          icon="pi pi-upload"
          className="p-button-help"
          //onClick={this.exportCSV}
        />
      </React.Fragment>
    );
  }

  nameBodyTemplate(rowData) {
    return <span>{rowData.name}</span>;
  }

  descriptionBodyTemplate(rowData) {
    return <span>{rowData.description}</span>;
  }

  languageBodyTemplate(rowData) {
    const index = this.state.languageOptions.findIndex(
      (el) => el.value === rowData.language
    );
    return <span>{this.state.languageOptions[index].label}</span>;
  }

  ownerBodyTemplate(rowData) {
    return <span>{rowData.owner}</span>;
  }

  actionBodyTemplate(rowData) {
    return (
      <React.Fragment>
        {this.props.user.username === rowData.owner ? (
          <React.Fragment>
            <Button
              icon="pi pi-pencil"
              className="p-button-rounded p-button-success p-mr-2"
              onClick={() => this.editClindoc(rowData)}
            />
            <Button
              icon="pi pi-trash"
              className="p-button-rounded p-button-warning p-mr-2"
              onClick={() => this.deleteClindoc(rowData)}
            />
          </React.Fragment>
        ) : (
          <React.Fragment> </React.Fragment>
        )}

        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-primary"
          onClick={() => this.previewClindoc(rowData)}
        />
      </React.Fragment>
    );
  }

  ratingsItemTemplate(option) {
    return (
      <div>
        <Rating
          className="image-text"
          value={Math.ceil(option.rating)}
          readonly
          cancel={false}
          stars={5}
        />
      </div>
    );
  }
  ratingsValueTemplate(option, props) {
    if (option) {
      return (
        <div>
          <Rating
            value={Math.ceil(option.rating)}
            readonly
            cancel={false}
            stars={5}
          />
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  }

  onLanguageFilterChange(languageList) {
    this.setState({ selectedLanguages: languageList }, () => {
      this.dt.filter(this.state.selectedLanguages, "language", "custom");
    });
  }

  onRatingFilterChange(rating) {
    this.setState({ selectedRating: rating }, () => {
      this.dt.filter(this.state.selectedRating, "average_rating", "custom");
    });
  }

  ratingFilterFunction(value, filter) {
    return filter === Math.ceil(value);
  }

  languageFilterFunction(value, filter) {
    return filter.includes(value);
  }

  render() {
    const ratingFilter = (
      <Dropdown
        value={this.state.selectedRating}
        options={[
          { rating: 5 },
          { rating: 4 },
          { rating: 3 },
          { rating: 2 },
          { rating: 1 },
        ]}
        showClear
        valueTemplate={this.ratingsValueTemplate}
        itemTemplate={this.ratingsItemTemplate}
        onChange={(e) => this.onRatingFilterChange(e.target.value)}
        optionLabel="rating"
        optionValue="rating"
        placeholder="All"
        className="p-column-filter"
      />
    );

    const languageFilter = (
      <MultiSelect
        id="languageFilter"
        options={this.state.languageOptions}
        value={this.state.selectedLanguages}
        filter={true}
        multiple
        panelStyle={{ width: "300px" }}
        style={{ maxWidth: "300px" }}
        placeholder="Search by language"
        onChange={(e) => {
          //this.setState({ selectedLanguages: e.target.value }, () => {
          //  this.dt.filter(this.state.selectedLanguages, "language", "custom");
          //});
          this.onLanguageFilterChange(e.target.value);
        }}
      />
    );

    const header = (
      <div
        className="table-header"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        Clinical Document Gallery
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => this.setState({ globalFilter: e.target.value })}
            placeholder="Global Search"
          />
        </span>
      </div>
    );

    const paginatorLeft = (
      <Button type="button" icon="pi pi-refresh" className="p-button-text" />
    );
    const paginatorRight = (
      <Button type="button" icon="pi pi-cloud" className="p-button-text" />
    );

    return (
      <div>
        <div className="card">
          <Toolbar className="p-mb-4" left={this.leftToolbarTemplate}></Toolbar>

          <DataTable
            value={this.props.clindocs}
            //lazy
            ref={(el) => (this.dt = el)}
            header={header}
            loading={this.props.loading}
            globalFilter={this.state.globalFilter}
            emptyMessage="No documents found."
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rowsPerPageOptions={[10, 20, 50]}
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
            rows={10}
          >
            <Column
              field="average_rating"
              header="Rating"
              sortable
              excludeGlobalFilter
              body={this.ratingBodyTemplate}
              filter
              filterElement={ratingFilter}
              value={this.selectedRating}
              filterMatchMode="custom"
              filterFunction={this.ratingFilterFunction}
            />
            <Column
              field="name"
              header="Name"
              body={this.nameBodyTemplate}
              sortable
              filter
              filterPlaceholder="Filter by name"
            />
            <Column
              field="language"
              header="Language"
              body={this.languageBodyTemplate}
              sortable
              filter
              //value={this.state.languageFilterValue}
              //onChange={(e) => this.setState({ languageFilterValue: e.value })}
              filterElement={languageFilter}
              value={this.selectedLanguages}
              filterPlaceholder="e.g. 'en' or 'nl'"
              filterMatchMode="custom"
              filterFunction={this.languageFilterFunction}
            />
            <Column
              field="owner"
              header="Author"
              sortable
              filter
              filterPlaceholder="Search by author"
              body={this.ownerBodyTemplate}
            />

            <Column header="Actions" body={this.actionBodyTemplate}></Column>
          </DataTable>
        </div>
        <GalleryClinDocPreviewPanel
          visible={this.state.showPreview}
          onHide={() => this.setState({ showPreview: false })}
        />
        <GalleryClinDocFormPanel
          visible={this.state.showEditor}
          languageOptions={this.state.languageOptions}
          onHide={() => this.setState({ showEditor: false })}
        />

        <Dialog
          header="Delete Document?"
          visible={this.state.showDeleteConfirmation}
          modal
          style={{ minWidth: "500px", width: "50vw" }}
          footer={helper.renderFooter(
            () => this.setState({ showDeleteConfirmation: false }),
            () => {
              this.setState({ showDeleteConfirmation: false });
              this.props.onDeleteClinDoc(this.props.selectedClinDoc);
            }
          )}
          onHide={() => this.setState({ showConfirmationModal: false })}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3"
              style={{ fontSize: "2rem" }}
            />
            <span>
              Are you sure you want to delete "{this.props.selectedClinDoc.name}
              "?
            </span>
          </div>
        </Dialog>
      </div>
    );
  }
}

//redux code

const mapStateToProps = function (store) {
  return {
    clindocs: store.galleryState.clindocs,
    selectedClinDoc: store.galleryState.selectedClinDoc,
    loading: store.galleryState.loading,
    user: store.auth.user,
    baseClindocs: store.clinDocState.present.clindocs,
  };
};

const mapDispatchToProps = function (dispatch, ownProps) {
  return {
    fetchClinDocs: function () {
      dispatch(fetchGalleryClindocs());
    },
    updateClinDocDetails: function (id) {
      dispatch(fetchGalleryClindocDetails(id));
    },
    onDeleteClinDoc: function (document) {
      dispatch(deleteGalleryClinDoc(document));
    },
    fetchBaseClindocs: function () {
      dispatch(clinDocActions.fetchClindocs());
    },
    createNewClindoc: function () {
      dispatch(createNewGalleryClindoc());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
