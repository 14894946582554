import {
  createFindingsArrayFromString,
  createOutputString,
  line_regex,
} from "../components/helperFunctions";

import * as actions from "../actions/types";
import produce from "immer";

export const initialClinDocState = {
  loading: false,
  clindocs: [],
  currentClinDoc: {
    description: "",
    inputString: "",
    findings: [],
    lines_selected: [],
    outputString: "",
  },
  error: null,
};

//set initial state to empty array in order to avoid returning undefined.
//reducer needs to be pure function (same result every time it is called for same argument)
export const clinDocReducer = (state = initialClinDocState, action) => {
  const { payload } = action;
  //console.log(action);

  switch (action.type) {
    //change string
    case actions.CHANGE_CLINDOC:
      let newString = payload.inputString;
      let newFindings = createFindingsArrayFromString(newString);

      let newClinDoc = {
        description: payload.description,
        inputString: newString,
        findings: newFindings,
        lines_selected: newString.match(line_regex).map((l) => true), //create a true for every line
        outputString: createOutputString(newString),
      };
      return { ...state, currentClinDoc: newClinDoc };

    case actions.CHANGE_VALUE:
      // change value for finding_id
      return produce(state, (draftState) => {
        const index = draftState.currentClinDoc.findings
          .map((e) => e.id)
          .indexOf(payload.id);
        draftState.currentClinDoc.findings[index].value = payload.value;
      });

    case actions.CHANGE_LINE_SELECTION:
      return produce(state, (draftState) => {
        draftState.currentClinDoc.lines_selected[payload.id] = payload.value;
      });

    case actions.REMOVE_FINDING:
      //remove finding by id
      return produce(state, (draftState) => {
        draftState.currentClinDoc.outputString = draftState.currentClinDoc.outputString.replace(
          "#" + payload.id + "#",
          ""
        );
      });

    case actions.TOGGLE_ALL_LINES:
      return produce(state, (draftState) => {
        draftState.currentClinDoc.lines_selected = draftState.currentClinDoc.lines_selected.map(
          (el) => payload.value
        );
      });

    case actions.FETCH_CLINDOCS_LOAD:
      return produce(state, (draftState) => {
        draftState.loading = true;
      });

    case actions.FETCH_CLINDOCS_SUCCESS:
      return produce(state, (draftState) => {
        draftState.clindocs = payload.clindocs;
        draftState.loading = false;
      });

    case actions.FETCH_CLINDOCS_ERROR:
      return produce(state, (draftState) => {
        draftState.error = payload.error;
        draftState.loading = false;
      });

    case actions.REMOVE_CLINDOC_FROM_LIST:
      return produce(state, (draftState) => {
        const index = draftState.clindocs.findIndex((c) => c.id === payload.id);
        if (index !== -1) draftState.clindocs.splice(index, 1);
      });

    case actions.ADD_CLINDOC_TO_LIST:
      return produce(state, (draftState) => {
        draftState.clindocs.push(payload.document);
      });

    default:
      return state;
  }
};
