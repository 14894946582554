import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
//import CssBaseline from "@material-ui/core/CssBaseline";
//import { ThemeProvider } from "@material-ui/core/styles";
//import theme from "./theme";
import ClintextNavbar from "./components/navbar";

//import app parts
import About from "./about";
import Home from "./home";
import Gallery from "./gallery.jsx";
import ClinDocEditor from "./editor";
import Clindoc from "./components/clinDoc";
import Login from "./components/account/login";
import Register from "./components/account/register";
import Profile from "./components/account/profile";
import PrivateRoute from "./components/utils/privateRoute";
import ScrollToTop from "./components/utils/scrollToTop";

//import redux
import { Provider } from "react-redux";
import * as actions from "./actions/types";
import store from "./store";

import { loadUser } from "./actions/auth";
import Footer, {
  TermsAndConditions,
  CookiePolicy,
  PrivacyPolicy,
} from "./components/footer";

const Logout = () => {
  store.dispatch({
    type: actions.LOGOUT,
  });
  return <Redirect to="/" />;
};

class App extends Component {
  state = {};

  constructor(props) {
    super(props);
    store.dispatch(loadUser());
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          <div>
            <ScrollToTop />
            <ClintextNavbar />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/about" component={About} />
              <PrivateRoute exact path="/app" component={Clindoc} />
              <PrivateRoute exact path="/gallery" component={Gallery} />
              <PrivateRoute exact path="/editor" component={ClinDocEditor} />
              <PrivateRoute exact path="/profile" component={Profile} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/logout" component={Logout} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/cookies" component={CookiePolicy} />
              <Route exact path="/terms" component={TermsAndConditions} />
              <Route exact path="/privacy" component={PrivacyPolicy} />
            </Switch>
            <Footer />
          </div>
        </Router>
      </Provider>
    );
  }

  /*
  oldRender() {
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          //{ CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. }
          <CssBaseline />
          <Router>
            <div>
              <ScrollToTop />
              <ClintextNavbar />
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/about" component={About} />
                <PrivateRoute exact path="/app" component={Clindoc} />
                <PrivateRoute exact path="/gallery" component={Gallery} />
                <PrivateRoute exact path="/editor" component={ClinDocEditor} />
                <PrivateRoute exact path="/profile" component={Profile} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/logout" component={Logout} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/cookies" component={CookiePolicy} />
                <Route exact path="/terms" component={TermsAndConditions} />
                <Route exact path="/privacy" component={PrivacyPolicy} />
              </Switch>
              <Footer />
            </div>
          </Router>
        </ThemeProvider>
      </Provider>
    );
  }*/
}

export default App;
