import { drfReduxApiCall } from "../components/helperFunctions";
import * as actions from "../actions/types";

import {
  pendingTask, // The action key for modifying loading state
  begin, // The action value if a "long" running task begun
  end, // The action value if a "long" running task ended
} from "react-redux-spinner";

export const fetchGalleryClindocs = () => (dispatch, getState) => {
  const fetchClindocsSuccess = (res) =>
    dispatch({
      type: actions.GALLERY_FETCH_CLINDOCS_SUCCESS,
      [pendingTask]: end,
      payload: {
        success: true,
        clindocs: res.data,
      },
    });

  const fetchClindocsLoading = () =>
    dispatch({
      type: actions.GALLERY_FETCH_CLINDOCS_LOAD,
      [pendingTask]: begin,
    });

  const fetchClindocsFailure = (error) =>
    dispatch({
      type: actions.GALLERY_FETCH_CLINDOCS_ERROR,
      [pendingTask]: end,
      payload: {
        error: error,
      },
    });

  const token = getState().auth.token;
  const url = "api/gallery/"; //trailing slash!!!!
  const method = "get";

  drfReduxApiCall(
    url,
    method,
    token,
    {},
    fetchClindocsLoading,
    fetchClindocsSuccess,
    fetchClindocsFailure
  );
};

export const fetchGalleryClindocDetails = (id) => (dispatch, getState) => {
  const fetchClindocsSuccess = (res) =>
    dispatch({
      type: actions.GALLERY_FETCH_CLINDOC_DETAILS_SUCCESS,
      [pendingTask]: end,
      payload: {
        success: true,
        document: res.data,
      },
    });

  const fetchClindocsLoading = () =>
    dispatch({
      type: actions.GALLERY_FETCH_CLINDOC_DETAILS_LOAD,
      [pendingTask]: begin,
    });

  const fetchClindocsFailure = (error) => {
    dispatch({
      type: actions.GALLERY_FETCH_CLINDOC_DETAILS_ERROR,
      [pendingTask]: end,
      payload: {
        error: error,
      },
    });

    dispatch({
      type: actions.ADD_MESSAGE,
      payload: {
        severity: "error",
        summary: "Error",
        detail: "An error occured while loading document",
      },
    });
  };

  const token = getState().auth.token;
  const url = "api/gallery/" + id + "/"; //trailing slash!!!!
  const method = "get";

  drfReduxApiCall(
    url,
    method,
    token,
    {},
    fetchClindocsLoading,
    fetchClindocsSuccess,
    fetchClindocsFailure
  );
};

export const saveGalleryClinDoc = (clindoc) => (dispatch, getState) => {
  const saveClinDocLoading = () =>
    dispatch({
      type: actions.GALLERY_SAVE_CLINDOC_LOAD,
      [pendingTask]: begin,
    });

  const saveClinDocSuccess = (res) => {
    dispatch({
      type: actions.GALLERY_SAVE_CLINDOC_SUCCESS, //list update is included in this action
      [pendingTask]: end,
      payload: res.data,
    });

    let message =
      "Succesfully " +
      (res.data.id === 0 ? "added new " : "saved ") +
      "document";
    //show success message
    dispatch({
      type: actions.ADD_MESSAGE,
      payload: {
        severity: "success",
        summary: "Success!",
        detail: message,
      },
    });
  };

  const saveClinDocError = (error) => {
    dispatch({
      type: actions.SAVE_CLINDOC_ERROR,
      [pendingTask]: end,
      payload: {
        error: error,
      },
    });

    dispatch({
      type: actions.ADD_MESSAGE,
      payload: {
        severity: "error",
        summary: "Error",
        detail: "An error occured while saving",
      },
    });
  };

  const token = getState().auth.token;
  const method = clindoc.id === 0 ? "post" : "put";
  const api_url_action = clindoc.id === 0 ? "" : clindoc.id + "/"; //trailing slash, important for django
  const api_url = "api/gallery/" + api_url_action;

  drfReduxApiCall(
    api_url,
    method,
    token,
    clindoc,
    saveClinDocLoading,
    saveClinDocSuccess,
    saveClinDocError
  );
};

export const deleteGalleryClinDoc = (clindoc) => (dispatch, getState) => {
  const deleteClinDocLoading = () =>
    dispatch({
      type: actions.GALLERY_DELETE_CLINDOC_LOAD,
      [pendingTask]: begin,
    });

  const deleteClinDocSuccess = (res) => {
    dispatch({
      type: actions.GALLERY_DELETE_CLINDOC_SUCCESS,
      [pendingTask]: end,
      payload: { id: clindoc.id },
    });

    dispatch({
      type: actions.ADD_MESSAGE,
      payload: {
        severity: "warn",
        summary: "Deleted!",
        detail: "Deleted document " + clindoc.name,
      },
    });
  };

  const deleteClinDocError = (error) => {
    dispatch({
      type: actions.DELETE_CLINDOC_ERROR,
      [pendingTask]: end,
      payload: {
        error: error,
      },
    });

    dispatch({
      type: actions.ADD_MESSAGE,
      payload: {
        severity: "error",
        summary: "Error",
        detail: "An error occured while deleting...",
      },
    });
  };

  const token = getState().auth.token;
  const api_url = "api/gallery/" + clindoc.id + "/"; //append slash, important for django
  const method = "delete";

  drfReduxApiCall(
    api_url,
    method,
    token,
    clindoc,
    deleteClinDocLoading,
    deleteClinDocSuccess,
    deleteClinDocError
  );
};

export const createNewGalleryClindoc = () => (dispatch, getState) => {
  dispatch({
    type: actions.GALLERY_NEW_CLINDOC,
  });
};

export const changeGalleryClinDocDetailField = (fieldName, value) => (
  dispatch,
  getState
) => {
  dispatch({
    type: actions.GALLERY_CLINDOC_DETAILS_CHANGE,
    payload: {
      fieldName: fieldName,
      value: value,
    },
  });
};

export const changeRating = (clindoc, rating) => (dispatch, getState) => {
  const saveClinDocRatingLoading = () =>
    dispatch({
      type: actions.GALLERY_CHANGE_CLINDOC_RATING_LOAD,
      [pendingTask]: begin,
    });

  const saveClinDocRatingSuccess = (res) => {
    dispatch({
      type: actions.GALLERY_CHANGE_CLINDOC_RATING_SUCCESS, //list update is included in this action
      [pendingTask]: end,
      payload: res.data,
    });
  };

  const saveClinDocRatingError = (error) => {
    dispatch({
      type: actions.GALLERY_CHANGE_CLINDOC_RATING_ERROR,
      [pendingTask]: end,
      payload: {
        error: error,
      },
    });

    dispatch({
      type: actions.ADD_MESSAGE,
      payload: {
        severity: "error",
        summary: "Error",
        detail: "An error occured while saving the rating",
      },
    });
  };

  const token = getState().auth.token;
  const method = clindoc.rating_id === -1 ? "post" : "put";
  const api_url_action =
    clindoc.rating_id === -1 ? "" : clindoc.rating_id + "/"; //trailing slash, important for django
  const api_url = "api/ratings/" + api_url_action;

  drfReduxApiCall(
    api_url,
    method,
    token,
    { clindoc: clindoc.id, rating: rating },
    saveClinDocRatingLoading,
    saveClinDocRatingSuccess,
    saveClinDocRatingError
  );
};

export const importClinDoc = (clindoc) => (dispatch, getState) => {
  const importClindocLoading = () => {
    dispatch({
      type: actions.GALLERY_IMPORT_CLINDOC_LOAD,
      [pendingTask]: begin,
    });
  };

  const importClindocSuccess = (res) => {
    dispatch({
      type: actions.GALLERY_IMPORT_CLINDOC_SUCCESS,
      [pendingTask]: end,
      payload: res.data,
    });

    let message = "Succesfully imported document";
    //show success message
    dispatch({
      type: actions.ADD_MESSAGE,
      payload: {
        severity: "success",
        summary: "Success!",
        detail: message,
      },
    });
  };

  const importClindocError = (error) => {
    dispatch({
      type: actions.GALLERY_IMPORT_CLINDOC_ERROR,
      [pendingTask]: end,
      payload: {
        error: error,
      },
    });

    dispatch({
      type: actions.ADD_MESSAGE,
      payload: {
        severity: "error",
        summary: "Error",
        detail: "An error occured while saving",
      },
    });
  };

  const token = getState().auth.token;
  const method = "post";
  const api_url = "api/clindocs/"; //trailing slash, important for django

  drfReduxApiCall(
    api_url,
    method,
    token,
    clindoc,
    importClindocLoading,
    importClindocSuccess,
    importClindocError
  );
};
