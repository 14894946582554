import React, { Component } from "react";
import { connect } from "react-redux";
//import { Adsense } from "@ctrl/react-adsense";

import { fetchClindocs } from "./actions/clindoc";
import { saveClinDoc, deleteClinDoc } from "./actions/editor";
import { addMessage } from "./actions/ui";

import ClindocSelectModal from "./components/selectModal";
import ClinDocDisplayPanel from "./components/clinDocDisplayPanel";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import * as actions from "./actions/types";

import ClinDocEditorPanel from "./components/clinDocEditorPanel";
import { renderFooter } from "./components/helperFunctions";

class ClinDocEditor extends Component {
  state = {
    //selectedClinDoc
    //currentString
    //findings
  };

  constructor(props) {
    super(props);
    this.state = {
      selectModalVisible: false,
      showConfirmationModal: false,
      showAddTextboxModal: false,
      showAddCheckboxModal: false,
      showAddMultiselectModal: false,
      showAddDropdownModal: false,
      showDeleteConfirmationModal: false,
      findings: [],
      currentCursorPosition: 0,
    };

    this.renderMenuBar = this.renderMenuBar.bind(this);
    this.handleInsertString = this.handleInsertString.bind(this);
  }

  renderMenuBar() {
    return [
      {
        label: "New",
        icon: "pi pi-fw pi-file",
        command: (event) => {
          //show comfirmation modal (are you sure?) in case an existing clindoc is loaded
          if (this.props.unsavedChanges) {
            this.setState({ showConfirmationModal: true });
          } else {
            this.props.onEditorNewClinDoc();
          }
        },
      },
      {
        label: "Open",
        icon: "pi pi-fw pi-folder-open",
        command: (event) => {
          this.setState({ selectModalVisible: true });
        },
      },
    ];
  }

  //handles inserting textbox
  handleInsertString(text) {
    let { currentCursorPosition } = this.state;
    let { selectedClinDoc } = this.props;

    let output = selectedClinDoc.inputString.substring(
      0,
      currentCursorPosition
    );
    output += text;
    output += selectedClinDoc.inputString.substring(currentCursorPosition);

    this.props.onChangeClinDocInputString(output);
  }

  componentDidUpdate(prevProps, prevState) {
    let newClinDoc = this.props.selectedClinDoc;
    let oldClinDoc = prevProps.selectedClinDoc;
    if (
      (newClinDoc.description !== oldClinDoc.description ||
        newClinDoc.name !== oldClinDoc.name ||
        newClinDoc.inputString !== oldClinDoc.inputString) &&
      !this.props.unsavedChanges
    ) {
      this.props.onSetUnsavedChanges(true);
    }
  }

  componentDidMount() {
    this.props.loadClindocs();
  }

  render() {
    let renderClindocPanel = () => {
      let panel = (
        <ClinDocDisplayPanel
          findings={this.state.findings}
          description={this.props.selectedClinDoc.description}
          inputString={this.props.selectedClinDoc.inputString}
        />
      );

      if (this.props.selectedClinDoc.inputString === "") {
        panel = (
          <p>
            Start typing the document string in the editor above to show a
            preview.{" "}
          </p>
        );
      }

      return panel;
    };

    let isNewClinDoc = this.props.selectedClinDoc.id === 0;

    return (
      <div className=" p-m-2">
        <div className="p-grid">
          <div className="p-col-6">
            <h2>Editor</h2>
            {isNewClinDoc ? (
              <h4>New clinical document</h4>
            ) : (
              <h4>Editing {this.props.selectedClinDoc.name}</h4>
            )}
          </div>
        </div>
        <div className="p-grid p-mt-2 p-p-2"></div>
        <div className="p-grid">
          <div className="p-col-9">
            <ClinDocEditorPanel
              baseZIndex={10}
              menuBarPrepend={this.renderMenuBar()}
              menuBarAppend={[]}
              inputString={this.props.selectedClinDoc.inputString}
              onSetUnsavedChanges={this.props.onSetUnsavedChanges}
              onChangeClinDocInputString={this.props.onChangeClinDocInputString}
            />
          </div>
          <div className="p-col-3">
            <div className="p-fluid">
              <div className="p-field">
                <label htmlFor="name" className="p-d-block">
                  Document Name
                </label>
                <InputText
                  id="name"
                  aria-describedby="name-help"
                  value={this.props.selectedClinDoc.name}
                  className="p-d-block"
                  placeholder="Enter a name"
                  onChange={(e) => {
                    this.props.onChangeClinDocName(e.target.value);
                  }}
                />
                <small id="name-help" className="p-d-block">
                  The name of the document, this field is required
                </small>
              </div>
              <div className="p-field">
                <label htmlFor="description" className="p-d-block">
                  Document description
                </label>
                <InputText
                  id="description"
                  aria-describedby="description-help"
                  value={this.props.selectedClinDoc.description}
                  className="p-d-block"
                  placeholder="Enter a description"
                  onChange={(e) => {
                    /*this.setState({
                      selectedClinDoc: {
                        ...this.state.selectedClinDoc,
                        description: e.target.value,
                      },
                    });*/
                    this.props.onChangeClinDocDescription(e.target.value);
                  }}
                />
                <small id="description-help" className="p-d-block">
                  A description of the document, this field is required
                </small>
              </div>
              <div className="p-field">
                <div className="p-grid  p-dir-rev">
                  <div className="p-col-6">
                    <Button
                      label="Save"
                      onClick={() => {
                        this.props.onSaveClinDoc(this.props.selectedClinDoc);
                      }}
                    />
                  </div>
                  {!isNewClinDoc ? (
                    <div className="p-col-6">
                      <Button
                        className="p-button-danger"
                        label="Delete"
                        onClick={() =>
                          this.setState({
                            showDeleteConfirmationModal: true,
                          })
                        }
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <h2>Preview </h2>
        {renderClindocPanel()}

        <ClindocSelectModal
          visible={this.state.selectModalVisible}
          onShow={() => this.props.loadClindocs()}
          onHide={() => this.setState({ selectModalVisible: false })}
          clindocList={this.props.clinDocs}
          handleSave={(document) => {
            this.props.onChangeEditorClinDoc(document);
            this.props.addClinDocChangedMessage(document);
            console.log(this.props);
          }}
        />
        <Dialog
          header="Confirmation"
          visible={this.state.showConfirmationModal}
          modal
          style={{ minWidth: "500px", width: "50vw" }}
          footer={renderFooter(
            () => this.setState({ showConfirmationModal: false }),
            () => {
              this.setState({ showConfirmationModal: false });
              this.props.onEditorNewClinDoc();
            }
          )}
          onHide={() => this.setState({ showConfirmationModal: false })}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3"
              style={{ fontSize: "2rem" }}
            />
            <span>Are you sure you want to proceed?</span>
          </div>
        </Dialog>
        <Dialog
          header="Delete ClinDoc?"
          visible={this.state.showDeleteConfirmationModal}
          modal
          style={{ minWidth: "500px", width: "50vw" }}
          footer={renderFooter(
            () => this.setState({ showDeleteConfirmationModal: false }),
            () => {
              this.setState({ showDeleteConfirmationModal: false });
              this.props.onDeleteClinDoc(this.props.selectedClinDoc);
            }
          )}
          onHide={() => this.setState({ showConfirmationModal: false })}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3"
              style={{ fontSize: "2rem" }}
            />
            <span>
              Are you sure you want to delete "{this.props.selectedClinDoc.name}
              "?
            </span>
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = function (store) {
  return {
    clinDocs: store.clinDocState.present.clindocs,
    selectedClinDoc: store.clinDocEditorState.currentEditorClinDoc,
    unsavedChanges: store.clinDocEditorState.editorUnsavedChanges,
  };
};

const mapDispatchToProps = function (dispatch, ownProps) {
  //todo: save function needs to be dispatched here
  return {
    loadClindocs: function () {
      dispatch(fetchClindocs());
    },
    onChangeEditorClinDoc: function (document) {
      dispatch({
        type: actions.CHANGE_EDITOR_CLINDOC,
        payload: { document: document },
      });
    },
    onSaveClinDoc: function (document) {
      dispatch(saveClinDoc(document));
      //dispatch(fetchClindocs());
    },
    onDeleteClinDoc: function (document) {
      dispatch(deleteClinDoc(document));
      //dispatch(fetchClindocs());
    },
    onChangeClinDocDescription: function (value) {
      dispatch({
        type: actions.CHANGE_EDITOR_DESCRIPTION,
        payload: {
          value: value,
        },
      });
    },
    onChangeClinDocName: function (value) {
      dispatch({
        type: actions.CHANGE_EDITOR_NAME,
        payload: {
          value: value,
        },
      });
    },
    onChangeClinDocInputString: function (value) {
      dispatch({
        type: actions.CHANGE_EDITOR_STRING,
        payload: {
          value: value,
        },
      });
    },
    onChangeClinDocId: function (value) {
      dispatch({
        type: actions.CHANGE_EDITOR_ID,
        payload: {
          value: value,
        },
      });
    },
    addClinDocChangedMessage: function (document) {
      dispatch(
        addMessage(
          "Document loaded.",
          document.name + " succesfully loaded.",
          "success"
        )
      );
    },
    onEditorNewClinDoc: function () {
      dispatch({
        type: actions.NEW_EDITOR_CLINDOC,
      });
    },
    onSetUnsavedChanges: function (value) {
      dispatch({
        type: actions.EDITOR_CLINDOC_CHANGE_UNSAVED_CHANGES,
        payload: { value: value },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClinDocEditor);
