import React, { Component } from "react";
import "primeflex/primeflex.css";

import Finding from "../components/finding";
import FindingsGroup from "../components/findingsGroup";

import {
  line_regex,
  createOutputString,
  createFindingsArrayFromString,
} from "../components/helperFunctions";

import { Card } from "primereact/card";
import { Checkbox } from "primereact/checkbox";

const reactStringReplace = require("react-string-replace");

/*
 *   Panel to render a string to a set of fidings in groups, purely for display purposes
 *   Deleting/contextmenu is not (yet?) implemented.
 */

class ClinDocDisplayPanel extends Component {
  state = {};

  constructor(props) {
    super(props);
    //initialize fings array
    this.state.findings = createFindingsArrayFromString(props.inputString);

    let lines = this.props.inputString.match(line_regex) || [];

    if (lines.length > 0) {
      this.state.lines_selected = lines.map((el) => {
        return true;
      });
    } else {
      this.state.lines_selected = [];
    }

    //bind this
    this.handleValueChange = this.handleValueChange.bind(this);
    this.getTopCheckboxValue = this.getTopCheckboxValue.bind(this);
    this.convertStringToJSX = this.convertStringToJSX.bind(this);
    this.groupSelectChangeHandler = this.groupSelectChangeHandler.bind(this);
  }

  convertStringToJSX(outputString, findings) {
    findings.forEach((finding) => {
      outputString = reactStringReplace(
        outputString,
        "#" + finding.id + "#",
        () => (
          <Finding
            key={finding.id}
            finding={finding}
            onChange={this.handleValueChange}
            showValueInTooltip={true}
          ></Finding>
        )
      );
    });
    return outputString;
  }

  handleValueChange(id, value) {
    // 1. Make a shallow copy of the findings
    let findings = [...this.state.findings];
    // 2. Make a shallow copy of the finding you want to mutate
    // Find the fiding in the findings array by id
    let i = findings.findIndex((el) => {
      return el.id === id;
    });
    let finding = findings[i];
    // 3. Replace the property you're intested in
    finding.value = value;
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    findings[i] = finding;
    this.setState({ findings: findings });
  }

  getTopCheckboxValue() {
    let value = null;
    let { lines_selected } = this.state; //get lines_selected from state

    if (lines_selected.length === 0) {
      return true;
    }

    let allTrue = (v) => v === true;
    let allFalse = (v) => v === false;
    if (lines_selected.every(allTrue)) {
      value = true;
    }

    if (lines_selected.every(allFalse)) {
      value = false;
    }
    return value;
  }

  groupSelectChangeHandler(id, value) {
    const lines_selected = [...this.state.lines_selected];
    lines_selected[id] = value;
    this.setState({ lines_selected: lines_selected });
  }

  componentDidUpdate(prevProps, prevState) {
    //change in document.inputstring? -> recreate findings

    if (prevProps.inputString !== this.props.inputString) {
      let lines = this.props.inputString.match(line_regex) || [];
      this.setState({
        findings: createFindingsArrayFromString(this.props.inputString),
        lines_selected: lines.map((el) => {
          return true;
        }),
      });
    }
  }

  render() {
    let outputString = createOutputString(this.props.inputString);
    let lines = outputString.match(line_regex) || [];

    return (
      <Card header={this.props.description}>
        <div className="p-d-flex p-flex-wrap">
          <div className={"p-d-flex p-flex-wrap p-p-2"}>
            <Checkbox
              className="p-mb-2 p-mr-2"
              checked={this.getTopCheckboxValue()}
              onChange={(e) => {
                let value = this.getTopCheckboxValue();
                this.setState({
                  lines_selected: this.state.lines_selected.map((el) => {
                    return !value;
                  }),
                });
              }}
            />
          </div>
        </div>
        <div className="border rounded">
          <div className="p-d-flex p-flex-wrap">
            {lines.map((line, i) => {
              return (
                <FindingsGroup
                  key={i}
                  id={i.toString()} //id should be a string by convention
                  selected={this.state.lines_selected[i]}
                  onSelect={this.groupSelectChangeHandler}
                  onMouseEnter={this.state.handleHover}
                  onMouseLeave={this.state.handleHover}
                >
                  {this.convertStringToJSX(line, this.state.findings)}
                </FindingsGroup>
              );
            })}
          </div>
        </div>
      </Card>
    );
  }
}

export default ClinDocDisplayPanel;
