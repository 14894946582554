import React, { Component } from "react";
import PropTypes from "prop-types";

import { Menubar } from "primereact/menubar";

//relative imports
import ClinDocEditorTextarea from "../components/clinDocEditorTextarea";
import AddTextBoxModal from "../components/addTextBoxModal";
import AddMultipleChoiceModal from "../components/addMultipleChoiceModal.jsx";
import AddCheckBoxModal from "../components/addCheckBoxModal.jsx";

class ClinDocEditorPanel extends Component {
  state = {
    showAddTextboxModal: false,
    showAddCheckboxModal: false,
    showAddMultiselectModal: false,
    showAddDropdownModal: false,
    currentCursorPosition: 0,
  };

  static propTypes = {
    baseZIndex: PropTypes.number,
    menuBarPrepend: PropTypes.array,
    menuBarAppend: PropTypes.array,
    //selectedClinDoc: PropTypes.object,
    inputString: PropTypes.string,
    onSetUnsavedChanges: PropTypes.func,
    onChangeClinDocInputString: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.renderMenuBar = this.renderMenuBar.bind(this);
    this.handleInsertString = this.handleInsertString.bind(this);
  }

  renderMenuBar() {
    const editorItems = [
      {
        label: "Add TextBox",
        icon: "pi pi-fw pi-minus",
        command: (event) => {
          this.setState({ showAddTextboxModal: true });
        },
      },
      {
        label: "Add Dropdown",
        icon: "pi pi-fw pi-ellipsis-v",
        command: (event) => {
          this.setState({ showAddDropdownModal: true });
        },
      },
      {
        label: "Add Multiple Select",
        icon: "pi pi-fw pi-ellipsis-h",
        command: (event) => {
          this.setState({ showAddMultiselectModal: true });
        },
      },
      {
        label: "Add Checkbox",
        icon: "pi pi-fw pi-check-square",
        command: (event) => {
          this.setState({ showAddCheckboxModal: true });
        },
      },
    ];

    const menuBarPrepend = this.props.menuBarPrepend || [];
    const { menuBarAppend } = this.props;
    const separator = [
      {
        separator: true,
      },
    ];
    return menuBarPrepend
      .concat(separator)
      .concat(editorItems)
      .concat(separator)
      .concat(menuBarAppend);
  }

  //handles inserting textbox
  handleInsertString(text) {
    let { currentCursorPosition } = this.state;
    let { inputString } = this.props;

    let output = inputString.substring(0, currentCursorPosition);
    output += text;
    output += inputString.substring(currentCursorPosition);

    this.props.onChangeClinDocInputString(output);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.inputString !== prevProps.inputString) {
      this.props.onSetUnsavedChanges(true);
    }
  }

  render() {
    //prerender editor field
    const renderEditor = () => {
      let inputString = this.props.inputString || "";

      return (
        <div>
          <Menubar model={this.renderMenuBar()} style={{ padding: 0 }} />
          <ClinDocEditorTextarea
            //getSelectionParams={(start, end) => {
            //  this.setState({ currentCursorPosition: start });
            //}}
            getSelectionParams={(start, end) => {
              this.setState({ currentCursorPosition: start });
            }}
            rows={Math.max(10, inputString.split("\n").length)}
            autoResize={true}
            style={{ width: 100 + "%" }}
            value={inputString}
            onChange={(e) =>
              this.props.onChangeClinDocInputString(e.target.value)
            }
          />
        </div>
      );
    };

    //final output
    return (
      <div>
        {renderEditor()}
        <AddTextBoxModal
          show={this.state.showAddTextboxModal}
          hide={() => this.setState({ showAddTextboxModal: false })}
          onSave={this.handleInsertString}
          baseZIndex={this.props.baseZIndex || 0}
        />
        <AddMultipleChoiceModal
          // Dropdown modal
          show={this.state.showAddDropdownModal}
          hide={() => this.setState({ showAddDropdownModal: false })}
          onSave={this.handleInsertString}
          dialogHeader="Add Dropdown"
          multipleChoiceTypeDenominator="DD"
          baseZIndex={this.props.baseZIndex || 0}
        />
        <AddMultipleChoiceModal
          // multiselect modal
          show={this.state.showAddMultiselectModal}
          hide={() => this.setState({ showAddMultiselectModal: false })}
          onSave={this.handleInsertString}
          dialogHeader="Add Multiselect"
          multipleChoiceTypeDenominator="MS"
          baseZIndex={this.props.baseZIndex || 0}
        />
        <AddCheckBoxModal
          show={this.state.showAddCheckboxModal}
          hide={() => this.setState({ showAddCheckboxModal: false })}
          onSave={this.handleInsertString}
          baseZIndex={this.props.baseZIndex || 0}
        />
      </div>
    );
  }
}

export default ClinDocEditorPanel;
