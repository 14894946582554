import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { loginUser } from "../../actions/auth";

import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";

import "primeflex/primeflex.css";
import { addMessage } from "../../actions/ui";

class Login extends Component {
  state = {
    username: "",
    password: "",
  };

  static propTypes = {};

  componentDidMount() {
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const username = rememberMe ? localStorage.getItem("username") : "";
    this.setState({ username, rememberMe });
  }

  handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { username, rememberMe } = this.state;
    localStorage.setItem("rememberMe", rememberMe);
    localStorage.setItem("username", rememberMe ? username : "");

    this.props.loginUser(this.state.username, this.state.password);
    console.log(this.props);
  };

  render() {
    if (this.props.auth.isAuthenticated) {
      return <Redirect to="/" />;
    }
    const { username, password } = this.state;
    let password_errors = this.props.auth.errors.password || [];
    let non_field_errors = this.props.auth.errors.non_field_errors || [];
    non_field_errors.forEach((msg) => {
      this.props.showErrorMessage(msg);
    });

    return (
      <div
        className="col-md-4 m-auto"
        style={{ justifySelf: "center", alignSelf: "center" }}
      >
        <div className="card mt-4 p-4">
          <form onSubmit={this.onSubmit}>
            <div className="p-fluid">
              <div className="p-field">
                <label htmlFor="username">Username</label>
                <InputText
                  id="username"
                  name="username"
                  value={username}
                  placeholder="Enter Username"
                  onChange={this.handleChange}
                />
              </div>
              <div className="p-field">
                <label htmlFor="password">Password</label>
                <Password
                  feedback={false}
                  id="password"
                  name="password"
                  value={password}
                  placeholder="Password"
                  onChange={this.handleChange}
                  className={password_errors.length > 0 ? "p-invalid" : ""}
                />
                <small id="password-error" className="p-invalid p-d-block">
                  <ul>
                    {password_errors.map((el) => (
                      <li key={el.toString()}>{el}</li>
                    ))}
                  </ul>
                </small>
              </div>
            </div>
            <div className="p-field-checkbox">
              <Checkbox
                inputId="remember"
                id="rememberMe"
                name="rememberMe"
                onChange={this.handleChange}
                checked={this.state["rememberMe"]}
              />
              <label htmlFor="remember">Remember Me</label>
            </div>

            <Button type="submit" label="Login" className="btn-block"></Button>
          </form>
          <div className="text-center">
            <small className="form-text text-muted">
              Don't have an account? <Link to="/register">Register Here</Link>
            </small>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = function (dispatch, ownProps) {
  return {
    showErrorMessage: function (errorMessage) {
      dispatch(addMessage("Error!", errorMessage, "error"));
    },
    loginUser: function (username, password) {
      dispatch(loginUser(username, password));
    },
  };
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
//export default connect(mapStateToProps, { loginUser })(Login);
export default connect(mapStateToProps, mapDispatchToProps)(Login);
