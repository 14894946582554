import React, { Component } from "react";
import PropTypes from "prop-types";
import { Checkbox } from "primereact/checkbox";

class FindingsGroup extends Component {
  state = {
    id: null,
  };

  static propTypes = {
    id: PropTypes.string,
    selected: PropTypes.bool,
    onSelect: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state.id = props.id;
    //this.state.checked = true;
    this.checkedHandler = this.checkedHandler.bind(this);
    this.handleHover = this.handleHover.bind(this);
    //this.state.isHovered = true;
  }

  //handleChangeValue = (e) => this.setState({ value: e.target.value });

  checkedHandler(event) {
    this.props.onSelect(this.state.id, event.checked);
    //this.setState({ [event.target.id]: event.target.value });
    //{(e) => this.setState({ checked: e.checked })}
  }

  handleHover(e) {
    //console.log(e);
    this.setState({
      isHovered: !this.state.isHovered,
    });
  }

  render() {
    const hoveredClass = this.state.isHovered ? "p-shadow-2" : "";
    const hoveredStyle = this.state.isHovered
      ? { background: "#E9ECEF" }
      : null;

    //width 100% voor hover effect.
    return (
      <div
        className={"p-d-flex p-flex-wrap p-p-2 " + hoveredClass}
        style={{ width: 100 + "%", ...hoveredStyle }}
        onMouseEnter={this.handleHover}
        onMouseLeave={this.handleHover}
      >
        <Checkbox
          className="p-mb-2 p-mr-2"
          onChange={this.checkedHandler}
          checked={this.props.selected}
        />
        {this.props.children}
      </div>
    );
  }
}
/*
{this.props.findings.map((f) => (
            <Finding
              key={f.id}
              id={f.id}
              finding={f} //finding updaten
              onDelete={this.handleDelete}
              onClick={this.handleClick}
              onUpdate={this.handleUpdate}
            />
          ))}*/

export default FindingsGroup;
