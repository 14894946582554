import * as actions from "./types";
import cookiesTool from "../components/utils/cookiesTool";

import { COOKIE_CONSENT } from "../config";

export const addMessage = (summary, detail, severity = "success") => (
  dispatch,
  getState
) => {
  return dispatch({
    type: actions.ADD_MESSAGE,
    payload: {
      severity: severity,
      summary: summary,
      detail: detail,
    },
  });
};

export const removeMessage = (id) => (dispatch, getState) => {
  return dispatch({
    type: actions.REMOVE_MESSAGE,
    payload: {
      id: id,
    },
  });
};

//save an object of consent: key => boolean
export const updateCookieSettings = (consent) => (dispatch, getState) => {
  consent.obligatory = true; //acts to demonstrate GDPR Compliance
  Object.keys(consent).forEach((key) => {
    cookiesTool.setActive(key, consent[key]);
  });

  return dispatch({
    type: actions.UPDATE_COOKIE_CONSENT,
    payload: {
      consent: consent,
      consentStored: true,
      hasLoaded: true,
    },
  });
};

export const loadCookieSettings = () => (dispatch, getState) => {
  let consent = {};
  Object.keys(COOKIE_CONSENT).forEach((key) => {
    consent[key] = cookiesTool.isActive(key);
  });

  const consentStored = consent.obligatory && consent.obligatory === 1;
  consent.obligatory = true;

  return dispatch({
    type: actions.UPDATE_COOKIE_CONSENT,
    payload: {
      consentStored: consentStored,
      consent: consent,
      hasLoaded: true,
    },
  });
};
