import React, { Component } from "react";
import { Adsense } from "@ctrl/react-adsense";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import Finding from "../components/finding";
import FindingsGroup from "./findingsGroup";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Checkbox } from "primereact/checkbox";
import { ContextMenu } from "primereact/contextmenu";
import ClindocSelectModal from "./selectModal";

import * as actions from "../actions/types";
import { fetchClindocs } from "../actions/clindoc";

//to improve legibility helper functions are grouped in seperate file.
import { line_regex, getFormattedOutputString } from "./helperFunctions";

import { ActionTypes, ActionCreators as UndoActionCreators } from "redux-undo";

const reactStringReplace = require("react-string-replace");

class ClinDoc extends Component {
  state = {
    line_focussed: null, //int of focussed group id
    formattedOutputString: "",
  };

  static propTypes = {
    findingsString: PropTypes.string,
    inputString: PropTypes.string, //the current string to be rendered
    findings: PropTypes.array,
    outputString: PropTypes.string,
    lines_selected: PropTypes.array, //array of groups selected
  };

  findingRefs = [];

  finding_cm_items = [
    {
      label: "Edit",
      icon: "pi pi-fw pi-pencil",
      command: (event) => {
        //cm.id = current finding id
        //get finding reference associated with current id
        //call current instance as .current
        //call public activateInputDisplay method
        this.findingRefs[this.cm.id].current.activateInputDisplay(event);
      },
    },
    {
      label: "Delete",
      icon: "pi pi-fw pi-trash",
      command: (event) => {
        this.props.findingDeleteHandler(this.cm.id);
      },
    },
  ];

  constructor(props) {
    super(props);

    //initialize formatted output string
    this.state.formattedOutputString = getFormattedOutputString(
      this.props.outputString,
      this.props.lines_selected,
      this.props.findings
    );

    this.state.selectModalVisible = false;

    //create reference for each finding
    this.props.findings.forEach((obj) => {
      this.findingRefs[obj.id] = React.createRef();
    });
  }

  componentDidMount() {
    this.props.loadClindocs();
    if (this.props.inputString === "") {
      this.setState({ selectModalVisible: true });
    }
  }

  convertStringToJSX(outputString, findings) {
    //replace finding_id in the arg string with the React finding element from the div.

    findings.forEach((finding) => {
      outputString = reactStringReplace(
        outputString,
        "#" + finding.id + "#",
        () => (
          <Finding
            key={finding.id}
            finding={finding}
            onChange={this.props.inputChangeHandler}
            onContextMenu={(e) => {
              this.cm.show(e);
              this.cm.id = finding.id;
              this.setState({ cm_id: finding.id });
            }}
            ref={this.findingRefs[finding.id]}
          ></Finding>
        )
      );
    });

    return outputString;
  }

  updateFormattedOutputString() {
    let { findings, lines_selected, outputString } = this.props;
    this.setState({
      formattedOutputString: getFormattedOutputString(
        outputString,
        lines_selected,
        findings
      ),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.lines_selected !== prevProps.lines_selected) {
      this.updateFormattedOutputString();
    }

    if (
      (JSON.stringify(this.props.findings) !==
        JSON.stringify(prevProps.findings)) |
      (this.props.outputString !== prevProps.outputString)
    ) {
      this.updateFormattedOutputString();
    }
  }

  getTopCheckboxValue() {
    let value = null;
    let { lines_selected } = this.props; //get lines_selected from state

    let allTrue = (v) => v === true;
    let allFalse = (v) => v === false;
    if (lines_selected.every(allTrue)) {
      value = true;
    }

    if (lines_selected.every(allFalse)) {
      value = false;
    }
    return value;
  }

  render() {
    let lines = this.props.outputString.match(line_regex) || [];

    return (
      <section id="clindoc">
        <ClindocSelectModal
          visible={this.state.selectModalVisible}
          onHide={() => this.setState({ selectModalVisible: false })}
          clindocList={this.props.clindocs}
          handleSave={this.props.changeDocumentHandler}
        />
        <ContextMenu
          model={this.finding_cm_items}
          ref={(el) => (this.cm = el)}
        />
        <div className="p-card p-component">
          <div className="p-card-body">
            <div className="p-d-flex p-jc-between">
              <div>
                <div className="p-card-title">Clindoc Generator</div>
                <div className="p-card-subtitle">{this.props.description}</div>
              </div>
              <div>
                <Adsense client="ca-pub-7640562161899788" slot="7259870550" />
              </div>
              <div>
                <Button
                  icon="pi pi-undo"
                  disabled={!this.props.canUndo}
                  onClick={this.props.onUndo}
                  className="p-mr-2 p-button-secondary"
                />
                <Button
                  onClick={this.props.onRedo}
                  disabled={!this.props.canRedo}
                  icon="pi pi-refresh"
                  className="p-mr-2 p-button-secondary"
                />
                <Button
                  icon="pi pi-th-large"
                  onClick={() => this.setState({ selectModalVisible: true })}
                  className="p-mr-2 p-button-secondary"
                />
              </div>
            </div>

            <div className="p-card-content">
              <div className="p-d-flex p-flex-wrap">
                <div className={"p-d-flex p-flex-wrap p-p-2"}>
                  <Checkbox
                    className="p-mb-2 p-mr-2"
                    checked={this.getTopCheckboxValue()}
                    onChange={this.props.topCheckboxHandler}
                  />
                </div>
              </div>
              <div className="border rounded">
                <div className="p-d-flex p-flex-wrap">
                  {lines.map((line, i) => {
                    return (
                      <FindingsGroup
                        key={i}
                        id={i.toString()} //id should be a string by convention
                        selected={this.props.lines_selected[i]}
                        onSelect={this.props.lineSelectionChangeHandler}
                        onMouseEnter={this.state.handleHover}
                        onMouseLeave={this.state.handleHover}
                      >
                        {this.convertStringToJSX(line, this.props.findings)}
                      </FindingsGroup>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Card title="Output">
          <div>
            <InputTextarea
              rows={lines.length}
              //cols={60}
              autoResize={true}
              style={{ width: 100 + "%" }}
              value={this.state.formattedOutputString}
              onChange={(e) =>
                this.setState({ formattedOutputString: e.target.value })
              }
            />
          </div>
        </Card>
      </section>
    );
  }
}

//redux code

const mapStateToProps = function (store) {
  return {
    description: store.clinDocState.present.currentClinDoc.description,
    clindocs: store.clinDocState.present.clindocs,
    inputString: store.clinDocState.present.currentClinDoc.inputString,
    findings: store.clinDocState.present.currentClinDoc.findings,
    lines_selected: store.clinDocState.present.currentClinDoc.lines_selected,
    outputString: store.clinDocState.present.currentClinDoc.outputString,
    canUndo: store.clinDocState.past.length > 0,
    canRedo: store.clinDocState.future.length > 0,
  };
};

const mapDispatchToProps = function (dispatch, ownProps) {
  return {
    inputChangeHandler: function (id, value) {
      dispatch({
        type: actions.CHANGE_VALUE,
        payload: { id: id, value: value },
      });
    },
    lineSelectionChangeHandler: function (id, value) {
      dispatch({
        type: actions.CHANGE_LINE_SELECTION,
        payload: { id: id, value: value },
      });
    },
    topCheckboxHandler: function (event) {
      dispatch({
        type: actions.TOGGLE_ALL_LINES,
        payload: { value: event.checked },
      });
    },
    findingDeleteHandler: function (id) {
      dispatch({
        type: actions.REMOVE_FINDING,
        payload: { id: id },
      });
    },
    changeDocumentHandler: function (document) {
      dispatch({
        type: actions.CHANGE_CLINDOC,
        payload: {
          inputString: document.inputString,
          description: document.description,
        },
      });

      dispatch({
        type: ActionTypes.CLEAR_HISTORY,
      });
    },
    loadClindocs: function () {
      console.log("Fetching clindocs...");
      dispatch(fetchClindocs());
    },
    onUndo: () => dispatch(UndoActionCreators.undo()),
    onRedo: () => dispatch(UndoActionCreators.redo()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClinDoc);
