import React, { Component } from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import { Button } from "primereact/button";

class Home extends Component {
  state = {
    text: "",
    showModal: false,
  };

  render() {
    return (
      <section id="home">
        <Jumbotron fluid>
          <Container>
            <h1>ClinTextGen</h1>

            <p className="lead">Quick Clinical Text Generation</p>
            <p>
              ClinTextGen is a tool to quickly create short text documents from
              a wide set of templates.
            </p>
          </Container>
        </Jumbotron>
        <Container style={{ marginTop: "5rem" }}>
          <div
            className="row"
            style={{
              marginBottom: "1.5rem",
              textAlign: "center",
            }}
          >
            <div className="col-lg-4">
              <div
                className="rounded-circle p-mb-2"
                style={{
                  backgroundImage: 'url("highway.jpg")',
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  marginLeft: "auto",
                  marginRight: "auto",
                  height: "200px",
                  width: "200px",
                  borderRadius: "50%",
                }}
              ></div>
              <h2>Fast</h2>
              <p>
                ClinTextGen was originally developped for use in Emergency
                Medicine, where high-quality medical reporting is needed and
                time is a luxury. ClinTextGen is low-weight, lightning fast and
                highly costumizable.
              </p>
            </div>
            <div className="col-lg-4">
              <div
                className="rounded-circle p-mb-2"
                style={{
                  backgroundImage: 'url("zen.jpg")',
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  marginLeft: "auto",
                  marginRight: "auto",
                  height: "200px",
                  width: "200px",
                  borderRadius: "50%",
                }}
              ></div>
              <h2>Easy to use</h2>
              <p>
                THe ClinTextGen App has been optimized trough rigid and
                thourough testing and advice from experts in the field, in order
                to provide the best possible user experience.
              </p>
            </div>
            <div className="col-lg-4">
              <div
                className="rounded-circle p-mb-2"
                style={{
                  backgroundImage: 'url("blocks.jpg")',
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  marginLeft: "auto",
                  marginRight: "auto",
                  height: "200px",
                  width: "200px",
                  borderRadius: "50%",
                }}
              ></div>

              <h2>Rich library</h2>
              <p>
                ClinTextGen contains a large library of prefabricated documents
                which you can easily adapt to your liking. Add, delete or modify
                content at the click of a mouse.
              </p>
            </div>
          </div>
        </Container>
        <Container>
          <div
            className="row featurette p-py-5"
            style={{ marginTop: "5rem", marginBottom: "5rem" }}
          >
            <div className="col-md-7">
              <h2 className="featurette-heading" style={{ marginTop: "7rem" }}>
                Get started.{" "}
                <span className="text-muted">It'll blow your mind.</span>
              </h2>
              <p className="lead">
                Creating an account takes less than one minute, and is free and
                easy.
              </p>
            </div>
            <div className="col-md-5">
              <div
                className="p-mb-2"
                style={{
                  backgroundImage: 'url("startup.jpg")',
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  marginLeft: "auto",
                  marginRight: "auto",
                  height: "500px",
                  width: "500px",
                }}
              ></div>
            </div>
          </div>
        </Container>

        <Container>
          <div className="card-deck mb-3 text-center">
            <div className="card mb-4 box-shadow">
              <div className="card-header">
                <h4 className="my-0 font-weight-normal">Free</h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title">
                  &euro;0 <small className="text-muted">/ mo</small>
                </h1>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>All features</li>
                  <li>Unlimited usage</li>
                  <li>Help center access</li>
                  <li>Ad supported</li>
                </ul>
                <Button
                  onClick={() => this.props.history.push("/register")}
                  label="Sign up for free"
                  style={{ width: "100%" }}
                  className="p-button-outlined p-button-lg"
                />
              </div>
            </div>
            <div className="card mb-4 box-shadow">
              <div className="card-header">
                <h4 className="my-0 font-weight-normal">Monthly</h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title">
                  &euro;1.99 <small className="text-muted">/ mo</small>
                </h1>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>All features</li>
                  <li>Unlimited usage</li>
                  <li>Help center access</li>
                  <li>Billed monthly</li>
                </ul>

                <Button
                  //onClick={() => this.props.history.push("/register")}
                  disabled={true}
                  label="Not yet available"
                  style={{ width: "100%" }}
                  className="p-button-lg"
                />
              </div>
            </div>
            <div className="card mb-4 box-shadow">
              <div className="card-header">
                <h4 className="my-0 font-weight-normal">Yearly</h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title">
                  &euro;19.99 <small className="text-muted">/ mo</small>
                </h1>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>All features</li>
                  <li>Unlimited usage</li>
                  <li>Help center access</li>
                  <li>Billed yearly</li>
                </ul>
                <Button
                  //onClick={() => this.props.history.push("/register")}
                  disabled={true}
                  label="Not yet available"
                  style={{ width: "100%" }}
                  className="p-button-lg"
                />
              </div>
            </div>
          </div>
        </Container>
      </section>
    );
  }
}

export default Home;
