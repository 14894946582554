import React, { Component } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { OrderList } from "primereact/orderlist";
import { renderFooter } from "../components/helperFunctions";
import PropTypes from "prop-types";

class AddMultipleChoiceModal extends Component {
  state = {
    value: "",
    choices: [],
    placeholder: "",
    currentId: 0,
  };

  //proptypes
  static propTypes = {
    values: PropTypes.string,
    placeholder: PropTypes.string,
    shortcut: PropTypes.string, //required
  };

  constructor(props) {
    super(props);
    this.handleSave = this.handleSave.bind(this);
    this.itemTemplate = this.itemTemplate.bind(this);
    this.handleAddChoice = this.handleAddChoice.bind(this);
  }

  handleSave() {
    let { placeholder, choices } = this.state;

    let findingString = "#" + this.props.multipleChoiceTypeDenominator + "|{";
    findingString += choices.map((el) => el.value).join(";");

    findingString += "}";
    findingString +=
      placeholder !== "" ? '|placeholder="' + placeholder + '"#' : "#";

    this.setState({ value: "", placeholder: "", choices: [] });
    this.props.onSave(findingString);
  }

  handleAddChoice() {
    let { currentId, value, choices } = this.state;
    this.setState({
      choices: [...choices, { id: currentId, value: value }],
      currentId: currentId + 1,
      value: "",
    });
  }

  itemTemplate(item) {
    return (
      <div className="p-grid">
        <div className="p-col-10">{item.value}</div>
        <div className="p-col-2">
          <Button
            icon="pi pi-times"
            className="p-button-rounded p-button-danger p-button-sm"
            onClick={() => {
              let { choices } = this.state;
              this.setState({
                choices: choices.filter((el) => {
                  return el.id !== item.id;
                }),
              });
            }}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <Dialog
        header={this.props.dialogHeader}
        visible={this.props.show}
        modal
        style={{ minWidth: "450px", width: "50vw" }}
        footer={renderFooter(
          this.props.hide,
          (e) => {
            this.handleSave(e);
            this.props.hide();
          },
          "Add",
          "Cancel"
        )}
        onHide={this.props.hide}
      >
        <p>
          Add choices using the field below the box. You can re-order the
          choices by using the buttons on the left.
        </p>
        <div className="p-col-12 p-md-12">
          <OrderList
            value={this.state.choices}
            itemTemplate={this.itemTemplate}
            header="Choices"
            onChange={(e) => this.setState({ choices: e.value })}
            listStyle={{ maxHeight: "190px" }}
            dataKey="id"
          ></OrderList>
        </div>
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-12 p-md-8 p-mt-2">
            <label htmlFor="value" className="p-d-block">
              <small>semicolon (;) is not an allowed character.</small>
            </label>
            <InputText
              id="value"
              aria-describedby="name-help"
              value={this.state.value}
              className="p-d-block"
              placeholder="Add a choice"
              keyfilter={/^[^;]+$/}
              onChange={(e) => {
                this.setState({
                  value: e.target.value,
                });
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  this.handleAddChoice();
                }
              }}
            />
          </div>
          <div className="p-field p-col-12 p-md-4 p-mt-2">
            <Button
              type="button"
              label="Add choice"
              onClick={this.handleAddChoice}
            />
          </div>

          <div className="p-field p-col-12 p-md-12">
            <label htmlFor="placeholder" className="p-d-block">
              Placeholder
            </label>
            <InputText
              id="placeholder"
              aria-describedby="placeholder-help"
              value={this.state.placeholder}
              className="p-d-block"
              placeholder="Enter a placeholder..."
              onChange={(e) =>
                this.setState({
                  placeholder: e.target.value,
                })
              }
            />
            <small id="placeholder-help" className="p-d-block">
              A placeholder is a text shown when the textbox is empty.
            </small>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default AddMultipleChoiceModal;
