import React, { Component } from "react";
import { Navbar, NavDropdown, Nav } from "react-bootstrap";
//import { Button } from "primereact/button";
import { Link } from "react-router-dom";

//import { ProgressBar } from "primereact/progressbar";
import { ProgressSpinner } from "primereact/progressspinner";
import { connect } from "react-redux";
import { Toast } from "primereact/toast";
import * as actions from "../actions/types";
import { addMessage } from "../actions/ui";

import { useSelector } from "react-redux";
import PropTypes from "prop-types";
//import NProgress from 'nprogress';
import {
  pendingTasksSelector,
  usePreviousPendingTasks,
} from "react-redux-spinner/lib/hooks";

class ClintextNavbar extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.state.stringMenuVisible = false;
    this.toggleStringMenu = this.toggleStringMenu.bind(this);

    this.renderSpinner = this.renderSpinner.bind(this);
    this.renderAuthenticationPart = this.renderAuthenticationPart.bind(this);
    this.showMessages = this.showMessages.bind(this);
  }

  /*
   *   Shows and hide the sidebar
   */
  toggleStringMenu() {
    //console.log(this.state.stringMenuVisible);
    if (!this.state.stringMenuVisible) {
      this.setState({ stringMenuVisible: true });
    } else {
      this.setState({ stringMenuVisible: false });
    }
  }

  renderSpinner = () => {
    console.log(this.props);
    if (this.props.loading) {
      return (
        <ProgressSpinner
          style={{ height: 40 }}
          strokeWidth="5"
        ></ProgressSpinner>
      );
    }
  };

  renderAuthenticationPart() {
    const { isAuthenticated } = this.props.auth;

    const renderAdminLogin = () => {
      return <NavDropdown.Item href="/admin/">Admin</NavDropdown.Item>;
    };

    if (isAuthenticated) {
      const { first_name, last_name, is_superuser } = this.props.auth.user;
      return (
        <NavDropdown
          title={"Logged in as " + first_name + " " + last_name}
          id="basic-nav-dropdown"
          alignRight
        >
          {is_superuser ? renderAdminLogin() : ""}
          <NavDropdown.Item as={Link} to="/profile">
            Profile
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/logout">
            Logout
          </NavDropdown.Item>
        </NavDropdown>
      );
    } else {
      return (
        <NavDropdown title="not logged in" id="basic-nav-dropdown" alignRight>
          <Link to="/login" className="dropdown-item">
            Login
          </Link>
          <Link to="/register" className="dropdown-item">
            Register
          </Link>
        </NavDropdown>
      );
    }
  }

  showMessages() {
    //console.log(this.props.messages);
    this.props.messages.forEach((el) => {
      this.toast.show({
        summary: el.summary,
        detail: el.detail,
        severity: el.severity,
      });
      this.props.removeMessage(el.id);
    });
  }
  componentDidUpdate(prevProps, prevState) {
    this.showMessages();
  }

  componentDidMount() {
    this.showMessages();
  }

  render() {
    return (
      <div>
        <Navbar bg="dark" variant="dark">
          <Navbar.Brand>
            <img
              src="/logo192.png"
              width="30"
              height="30"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />{" "}
            ClinTexGen Alpha 0.4
          </Navbar.Brand>
          <Nav className="mr-auto">
            <Link to="/" className="nav-link">
              Home
            </Link>
            <Link to="/about" className="nav-link">
              About
            </Link>
            <Nav.Link as={Link} to="/app">
              App
            </Nav.Link>
            <Nav.Link as={Link} to="/editor">
              Editor
            </Nav.Link>
            <Nav.Link as={Link} to="/gallery">
              Gallery
            </Nav.Link>
          </Nav>
          <Nav className="justify-content-end">
            <CustomSpinner />
            {this.renderAuthenticationPart()}
          </Nav>
        </Navbar>
        <Toast
          ref={(el) => (this.toast = el)}
          //onRemove={(el) => this.props.removeMessage(el)}
        />
      </div>
    );
  }
}

const mapStateToProps = function (store) {
  return {
    loading: store.clinDocState.loading,
    auth: store.auth,
    messages: store.uIState.messages,
    pendingTasks: store.pendingTasks,
  };
};

const mapDispatchToProps = function (dispatch, ownProps) {
  return {
    removeMessage: (e) => {
      dispatch({ type: actions.REMOVE_MESSAGE, payload: e });
    },
    addMessage: (summary, detail, severity) => {
      dispatch(addMessage(summary, detail, severity));
    },
  };
};

const CustomSpinner = ({ config }) => {
  const pendingTasks = useSelector(pendingTasksSelector);
  const prevPendingTasks = usePreviousPendingTasks(pendingTasks);
  const diff = pendingTasks - prevPendingTasks;

  if (diff > 0) {
    return (
      <ProgressSpinner style={{ height: 40 }} strokeWidth="5"></ProgressSpinner>
    );
  } else {
    return null;
  }
};

CustomSpinner.propTypes = {
  config: PropTypes.object,
  pendingTasks: PropTypes.number,
};

CustomSpinner.defaultProps = {
  config: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(ClintextNavbar);
