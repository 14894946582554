import React from "react";
import PropTypes from "prop-types";
//import { classNames } from "primereact/components/utils";
import classNames from "classnames";
import ObjectUtils from "primereact/components/utils/ObjectUtils";

import { InputTextarea } from "primereact/inputtextarea";

//TODO: fix error warning because inputTextArea passes all undefined props as ...config in source code...
class ClinDocEditorTextarea extends InputTextarea {
  constructor(props) {
    super(props);
    this.getSelectionParams = props.getSelectionParams;
    this.updateSelections = this.updateSelections.bind(this);
  }

  static propTypes = {
    getSelectionParams: PropTypes.func,
  };

  static defaultProps = {
    autoResize: false,
    onInput: null,
    tooltip: null,
    tooltipOptions: null,
    getSelectionParams: null,
  };

  updateSelections() {
    if (this.getSelectionParams) {
      this.getSelectionParams(
        this.element.selectionStart,
        this.element.selectionEnd
      );
    }
  }

  onFocus(e) {
    super.onFocus();
    this.updateSelections();
  }

  onKeyUp(e) {
    super.onKeyUp(e);
    this.updateSelections();
  }

  onClick(e) {
    super.onClick(e);
    this.updateSelections();
  }

  onBlur(e) {
    super.onBlur();
    this.updateSelections();
  }

  renderDEPRECATED() {
    return (
      <textarea
        className="p-inputtextarea p-inputtext p-component p-inputtextarea-resizable"
        rows={this.props.rows}
        style={{ width: "100%", overflow: "hidden" }}
        ref={(input) => (this.element = input)}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onKeyUp={this.onKeyUp}
        onInput={this.onInput}
      ></textarea>
    );
  }

  render() {
    const className = classNames(
      "p-inputtextarea p-inputtext p-component",
      {
        "p-disabled": this.props.disabled,
        "p-filled":
          (this.props.value != null &&
            this.props.value.toString().length > 0) ||
          (this.props.defaultValue != null &&
            this.props.defaultValue.toString().length > 0),
        "p-inputtextarea-resizable": this.props.autoResize,
      },
      this.props.className
    );

    let textareaProps = ObjectUtils.findDiffKeys(
      this.props,
      InputTextarea.defaultProps
    );

    //delete unwanted prop to supress react error
    delete textareaProps.getSelectionParams;

    return (
      <textarea
        {...textareaProps}
        className={className}
        rows={this.props.rows}
        ref={(input) => (this.element = input)}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onKeyUp={this.onKeyUp}
        onInput={this.onInput}
      ></textarea>
    );
  }
}

export default ClinDocEditorTextarea;
