import React from "react";
import { Button } from "primereact/button";
import axios from "axios";
import * as app_config from "../config";

export const finding_regex = /(#(?:DD|MS|CB|TB)\|[^#]+#)/gi;
export const placeholderRegex = /(?:placeholder="([^"]+))"/i;
export const replacement_regex = /#(f_\d)#/gi;
export const line_regex = /([^\n]+)/gi;
export const splitvar = "#!-#"; //unique variable to make it very improbably someone would choose this by accident

/*
 * inputs a finding string andn returns the type
 * No longn used
 */
export const determineType = (stringType) => {
  switch (stringType.substring(1, 3)) {
    case "MS":
      return "multiselect";
    case "TB":
      return "textbox";
    case "DD":
      return "dropdown";
    case "CB":
      return "checkbox";
    default:
      return "textbox";
  }
};

export const myReplaceAll = (str, find, replace) => {
  const escapeRegExp = (string) => {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  };

  const backwardsCompatibleReplaceAll = (str, find, replace) => {
    return str.replace(new RegExp(escapeRegExp(find), "g"), replace);
  };

  if (String.prototype.replaceAll) {
    return str.replaceAll(find, replace);
  } else {
    return backwardsCompatibleReplaceAll(str, find, replace);
  }
};

/*
 * create string with findings strings replaced with ids.
 */
export const createOutputString = (currentString) => {
  let outputStrings = [];
  let f_index = 0;
  let ht_index = 0;

  currentString.split("\n").forEach((line, i) => {
    let m = line.match(finding_regex) || [];

    let res = line.replace(finding_regex, splitvar);
    //remove empty strings from array.
    let ht = res.split(splitvar).filter(function (el) {
      return el !== "";
    });

    m.forEach((findingString, i) => {
      line = line.replace(findingString, "#fi_" + f_index + "#");
      f_index++;
    });

    ht.forEach((ht_string, i) => {
      line = line.replace(ht_string, "#ht_" + ht_index + "#");
      ht_index++;
    });

    //push to array
    outputStrings = [...outputStrings, line];
  });

  return outputStrings.join("\n");
};

export const getOptionsFromString = (findingString) => {
  let optionString = findingString.substring(5, findingString.length - 1);
  let options = [];
  optionString
    .split("|")[0] //get first options
    .substring(0, optionString.split("|")[0].length - 1) //drop last "}"
    .split(";")
    .forEach((element, index) => {
      //options.push({ label: element, value: element });
      options.push(element);
    });
  return options;
};

export const createFindingsArrayFromString = (currentString) => {
  let findings = [];
  let m = currentString.match(finding_regex) || [];

  m.forEach((findingString, i) => {
    let obj = {};
    obj.id = "fi_" + i;
    obj.type = determineType(findingString);
    obj.findingString = findingString;

    let placeholder = findingString.match(placeholderRegex);
    if (placeholder) {
      obj.hasPlaceholder = true;
      obj.placeholder = placeholder[1];
    } else {
      obj.hasPlaceholder = false;
    }

    switch (obj.type) {
      case "dropdown":
        obj.options = getOptionsFromString(findingString);
        obj.value = obj.options[0];
        break;
      case "multiselect":
        obj.options = getOptionsFromString(findingString);
        obj.value = obj.options;
        break;
      case "textbox":
        let optionString = findingString.substring(4, findingString.length - 1);
        obj.value = optionString.split("|")[0];
        break;

      case "checkbox":
        let cbOptions = findingString
          .substring(4, findingString.length - 1)
          .split("|");
        //console.log(cbOptions);

        obj.hasPlaceholder = true;
        obj.value = "";
        obj.placeholder = cbOptions[0];
        obj.true_text = cbOptions[1];
        obj.false_text = cbOptions[2];
        obj.checked = false;

        break;
      default:
        break;
    }

    //equivalent to findings.push?
    findings = [...findings, obj];
  });

  let ht_index = 0;
  //first remove newline as this would complicate things
  //let res = currentString.replace("\n", "");
  currentString.split("\n").forEach((line) => {
    let res = line.replace(finding_regex, splitvar);

    //remove empty strings from array.
    let ht = res.split(splitvar).filter(function (el) {
      return el !== "";
    });

    ht.forEach((ht_text) => {
      let obj = {};
      obj.id = "ht_" + ht_index;
      obj.type = "helpertext";
      obj.value = ht_text;
      obj.hasPlaceholder = false;

      findings = [...findings, obj];

      ht_index++;
    });
  });

  return findings;
};

//getFormattedOutputString(this.state.outputString, this.state.lines_selected, this.state.findings)
export const getFormattedOutputString = (
  outputString,
  lines_selected,
  findings
) => {
  let formattedOutputString = outputString;
  //only keep lines that are focussed
  formattedOutputString = formattedOutputString
    // split by newline
    .split("\n")
    //keep only lines that rare selected
    .filter((line, i) => {
      return lines_selected[i] ? line : "";
    })
    //rejoin by newline
    .join("\n");

  findings.forEach((finding) => {
    let value = "";
    value = Array.isArray(finding.value)
      ? finding.value.join(", ")
      : finding.value;
    formattedOutputString = formattedOutputString.replace(
      "#" + finding.id + "#",
      value
    );
  });
  // replace all findings with their values
  // replace '..' with '.', ',,' with ',', '.,' with '.' and ',.' 'with ','
  formattedOutputString = myReplaceAll(formattedOutputString, "  ", " ");
  formattedOutputString = myReplaceAll(formattedOutputString, " ,", ",");
  formattedOutputString = myReplaceAll(formattedOutputString, " .", ".");
  formattedOutputString = myReplaceAll(formattedOutputString, ", ,", ",");
  formattedOutputString = myReplaceAll(formattedOutputString, ". .", ",");
  formattedOutputString = myReplaceAll(formattedOutputString, ",,", ",");
  formattedOutputString = myReplaceAll(formattedOutputString, ".,", ".");
  formattedOutputString = myReplaceAll(formattedOutputString, ",.", ".");

  return formattedOutputString;
};

export const stripLastChar = (string) => {
  return string.substr(0, string.length - 1);
};

export const renderFooter = (
  cancelFunction,
  confirmFunction,
  yesLabel = "Yes",
  noLabel = "No"
) => {
  return (
    <div>
      <Button
        label={noLabel}
        icon="pi pi-times"
        onClick={cancelFunction}
        className="p-button-text"
      />
      <Button
        label={yesLabel}
        icon="pi pi-check"
        onClick={confirmFunction}
        autoFocus
      />
    </div>
  );
};

export const drfReduxApiCall = (
  url, //the url to call
  method, //["get", "post", "put", "delete"]
  token = "", //authentication token
  data = {},
  load_function, //load function, no args
  success_function, //success function, arg = requst
  error_function //errror function, arr = err
) => {
  //set loading state
  load_function();

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    method: method,
    data: data,
    url: app_config.API_URL + url,
  };

  if (token !== "") {
    config.headers["Authorization"] = `JWT ${token}`;
  }

  axios(config)
    .then((res) => {
      success_function(res);
    })
    .catch((err) => {
      error_function(err);
    });
};
