import React from "react";
//import PropTypes from "prop-types";
import classNames from "classnames";
import KeyFilter from "primereact/components/keyfilter/KeyFilter";
//import { tip } from "primereact/components/tooltip/Tooltip";
import DomHandler from "primereact/components/utils/DomHandler";
import ObjectUtils from "primereact/components/utils/ObjectUtils";
import { InputText } from "primereact/inputtext";

class AutoresizeInputText extends InputText {
  static defaultProps = {
    onInput: null,
    onKeyPress: null,
    keyfilter: null,
    validateOnly: false,
    tooltip: null,
    tooltipOptions: null,
    autoResize: null,
  };

  constructor(props) {
    super(props);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onInput = this.onInput.bind(this);
  }

  onFocus(e) {
    if (this.props.autoResize) {
      this.resize();
    }

    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  }

  onBlur(e) {
    if (this.props.autoResize) {
      this.resize();
    }

    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  }
  onKeyUp(e) {
    if (this.props.autoResize) {
      this.resize();
    }

    if (this.props.onKeyUp) {
      this.props.onKeyUp(e);
    }
  }

  onInput(e) {
    let validatePattern = true;
    if (this.props.keyfilter && this.props.validateOnly) {
      validatePattern = KeyFilter.validate(e, this.props.keyfilter);
    }

    if (this.props.autoResize) {
      this.resize();
    }

    if (!this.props.onChange) {
      if (e.target.value.length > 0) DomHandler.addClass(e.target, "p-filled");
      else DomHandler.removeClass(e.target, "p-filled");
    }

    if (this.props.onInput) {
      this.props.onInput(e, validatePattern);
    }

    if (this.props.onInput) {
      this.props.onInput(e);
    }
  }

  resize() {
    if (DomHandler.isVisible(this.element)) {
      if (!this.cachedScrollWidth) {
        this.cachedScrollHeight = this.element.scrollWidth;
        this.element.style.overflow = "hidden";
      }

      if (this.cachedScroll !== this.element.scrollWidth) {
        this.element.style.width = "";
        this.element.style.width = this.element.scrollWidth + "px";

        if (
          parseFloat(this.element.style.width) >=
          parseFloat(this.element.style.maxWidth)
        ) {
          this.element.style.overflowY = "scroll";
          this.element.style.width = this.element.style.maxWidth;
        } else {
          this.element.style.overflow = "hidden";
        }

        this.cachedScrollWidth = this.element.scrollWidth;
      }
    }
  }

  componentDidMount() {
    if (this.props.tooltip) {
      this.renderTooltip();
    }

    if (this.props.autoResize) {
      this.resize();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tooltip !== this.props.tooltip) {
      if (this.tooltip) this.tooltip.updateContent(this.props.tooltip);
      else this.renderTooltip();
    }

    if (this.props.autoResize) {
      this.resize();
    }
  }

  render() {
    const className = classNames(
      "p-inputtext p-component",
      {
        "p-disabled": this.props.disabled,
        "p-filled":
          (this.props.value != null &&
            this.props.value.toString().length > 0) ||
          (this.props.defaultValue != null &&
            this.props.defaultValue.toString().length > 0),
        "p-inputtextarea-resizable": this.props.autoResize,
      },
      this.props.className
    );

    let inputProps = ObjectUtils.findDiffKeys(
      this.props,
      AutoresizeInputText.defaultProps
    );

    return (
      <input
        {...inputProps}
        className={className}
        ref={(input) => (this.element = input)}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onKeyUp={this.onKeyUp}
        onInput={this.onInput}
      />
    );
  }
}

export default AutoresizeInputText;
