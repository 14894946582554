import * as actions from "../actions/types";
import produce from "immer";
import { COOKIE_CONSENT } from "../config";

export const initialUIState = {
  nextMessageId: 0,
  messages: [],
  cookies: {
    consentStored: false,
    consentActive: COOKIE_CONSENT,
    hasLoaded: false,
  },
};

export function uIReducer(state = initialUIState, action) {
  const { payload } = action;

  switch (action.type) {
    case actions.ADD_MESSAGE:
      const messages = [
        ...state.messages,
        {
          id: state.nextMessageId,
          detail: payload.detail,
          summary: payload.summary || "",
          severity: payload.severity || "success",
        },
      ];
      const nextMessageId = state.nextMessageId + 1;
      return {
        ...state,
        messages: messages,
        nextMessageId: nextMessageId,
      };

    case actions.REMOVE_MESSAGE:
      return produce(state, (draftState) => {
        draftState.messages.splice(
          draftState.messages.findIndex((m) => m.id === payload.id),
          1
        );
      });

    case actions.UPDATE_COOKIE_CONSENT:
      return produce(state, (draftState) => {
        Object.keys(payload.consent).forEach((key) => {
          draftState.cookies.consentActive[key] = payload.consent[key];
          draftState.cookies.consentStored = payload.consentStored;
          draftState.cookies.hasLoaded = payload.hasLoaded;
        });
      });

    // this is where the magic happens
    // if we stick to convention, loading indicator is shown automatically
    // [key] below adds a field with an evaluated key value.
    /*
    case type.endsWith("_LOAD"):
      return {
        ...state,
        loading: true,
        [actionName]: {
          pending: true,
        },
      };

    case type.endsWith("_SUCCESS") || type.endsWith("_ERROR"):
      return {
        ...state,
        [actionName]: {
          pending: false,
        },
      };*/

    default:
      return state;
  }
}
