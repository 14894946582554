import * as actions from "./types";
import axios from "axios";
import * as app_config from "../config";

import {
  pendingTask, // The action key for modifying loading state
  begin, // The action value if a "long" running task begun
  end, // The action value if a "long" running task ended
} from "react-redux-spinner";

import { ActionTypes } from "redux-undo";

/*
export const startLoading = () => (dispatch, getState) => {
  dispatch({
    type: actions.START_LOADING,
    payload: {},
  });
};

export const stopLoading = () => (dispatch, getState) => {
  dispatch({
    type: actions.STOP_LOADING,
    payload: {},
  });
};
*/

export const fetchClindocs = () => (dispatch, getState) => {
  dispatch(fetchClindocsLoading());

  const token = getState().auth.token;

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (token) {
    config.headers["Authorization"] = `JWT ${token}`;
  }

  axios
    .get(app_config.API_URL + `api/clindocs/`, config)
    .then((res) => {
      dispatch(fetchClindocsSuccess(res.data));

      dispatch({
        type: ActionTypes.CLEAR_HISTORY,
      });
    })
    .catch((err) => {
      dispatch(fetchClindocsFailure(err.message));
    });
};

const fetchClindocsSuccess = (data) => ({
  type: actions.FETCH_CLINDOCS_SUCCESS,
  [pendingTask]: end,
  payload: {
    success: true,
    clindocs: data,
  },
});

const fetchClindocsLoading = () => ({
  type: actions.FETCH_CLINDOCS_LOAD,
  [pendingTask]: begin,
});

const fetchClindocsFailure = (error) => ({
  type: actions.FETCH_CLINDOCS_ERROR,
  [pendingTask]: end,
  payload: {
    error: error,
  },
});
