import React, { Component } from "react";

import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { renderFooter } from "../components/helperFunctions";

class AddTextBoxModal extends Component {
  state = {
    value: "",
    placeholder: "",
  };

  constructor(props) {
    super(props);
    this.handleSave = this.handleSave.bind(this);
  }

  handleSave() {
    let { placeholder, value } = this.state;
    this.setState({ value: "", placeholder: "" });
    let findingString = "#TB|" + value;
    findingString +=
      placeholder !== "" ? '|placeholder="' + placeholder + '"#' : "#";

    this.props.onSave(findingString);
  }

  render() {
    return (
      <Dialog
        header="Add Textbox"
        visible={this.props.show}
        modal
        style={{ minWidth: "450px", width: "50vw" }}
        footer={renderFooter(
          this.props.hide,
          (e) => {
            this.handleSave(e);
            this.props.hide();
          },
          "Add",
          "Cancel"
        )}
        onHide={this.props.hide}
      >
        <div className="confirmation-content">
          <div className="p-fluid">
            <div className="p-field">
              <label htmlFor="value" className="p-d-block">
                Value
              </label>
              <InputText
                id="value"
                aria-describedby="name-help"
                value={this.state.value}
                className="p-d-block"
                placeholder="Default value"
                onChange={(e) => {
                  this.setState({
                    value: e.target.value,
                  });
                }}
              />
              <small id="name" className="p-d-block">
                The text displayed in the textbox.
              </small>
            </div>
            <div className="p-field">
              <label htmlFor="placeholder" className="p-d-block">
                Placeholder
              </label>
              <InputText
                id="placeholder"
                aria-describedby="placeholder-help"
                value={this.state.placeholder}
                className="p-d-block"
                placeholder="Enter a placeholder..."
                onChange={(e) =>
                  this.setState({
                    placeholder: e.target.value,
                  })
                }
              />
              <small id="placeholder-help" className="p-d-block">
                A placeholder is a text shown when the textbox is empty.
              </small>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default AddTextBoxModal;
