import React from "react";
//import PropTypes from "prop-types";
import classNames from "classnames";
//import KeyFilter from "primereact/components/keyfilter/KeyFilter";
//import { tip } from "primereact/components/tooltip/Tooltip";
import DomHandler from "primereact/components/utils/DomHandler";
//import ObjectUtils from "primereact/components/utils/ObjectUtils";
import { Dropdown } from "primereact/components/dropdown/Dropdown";
import { DropdownPanel } from "primereact/components/dropdown/DropdownPanel";

import { CSSTransition } from "react-transition-group";

class AutoresizeDropdown extends Dropdown {
  updateInputField() {
    super.updateInputField();
    this.resize();
  }

  componentDidMount() {
    super.componentDidMount();
    this.resize();
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);
    this.resize();
  }

  resize() {
    //console.log("resize called");
    if (this.props.editable) {
      let element = this.input;
      //let offset = Math.abs(
      //  element.parentElement.scrollWidth - element.scrollWidth
      //);
      //offset = 0;

      if (DomHandler.isVisible(element)) {
        if (!this.cachedScrollWidth) {
          this.cachedScrollWidht = element.scrollWidth;
          element.style.overflow = "hidden";
        }

        if (this.cachedScroll !== element.scrollWidth) {
          element.style.width = "";
          element.style.width = element.scrollWidth + "px";
          //console.log(offset);

          if (
            parseFloat(element.style.width) >=
            parseFloat(element.style.maxWidth)
          ) {
            element.style.overflowX = "scroll";
            element.style.width = this.element.style.maxWidth;
          } else {
            element.style.overflow = "hidden";
          }

          this.cachedScrollWidth = element.scrollWidth;
        }
      }
    }
  }

  render() {
    let className = classNames(
      "p-dropdown p-component p-inputwrapper",
      this.props.className,
      {
        "p-disabled": this.props.disabled,
        "p-focus": this.state.focused,
        "p-dropdown-clearable": this.props.showClear && !this.props.disabled,
        "p-inputwrapper-filled": this.props.value,
        "p-inputwrapper-focus": this.state.focused,
      }
    );
    let selectedOption = this.findOption(this.props.value);

    let hiddenSelect = this.renderHiddenSelect(selectedOption);
    let keyboardHelper = this.renderKeyboardHelper();
    let labelElement = this.renderLabel(selectedOption);
    let dropdownIcon = this.renderDropdownIcon();
    let items = this.renderItems(selectedOption);
    let filterElement = this.renderFilter();
    let clearIcon = this.renderClearIcon();

    return (
      <div
        id={this.id}
        ref={(el) => (this.container = el)}
        className={className}
        style={this.props.style}
        onClick={this.onClick}
        onMouseDown={this.props.onMouseDown}
        onContextMenu={this.props.onContextMenu}
      >
        {keyboardHelper}
        {hiddenSelect}
        {labelElement}
        {clearIcon}
        {dropdownIcon}
        <CSSTransition
          classNames="p-connected-overlay"
          in={this.state.overlayVisible}
          timeout={{ enter: 120, exit: 100 }}
          unmountOnExit
          onEnter={this.onOverlayEnter}
          onEntered={this.onOverlayEntered}
          onExit={this.onOverlayExit}
          onExited={this.onOverlayExited}
        >
          <DropdownPanel
            ref={(el) => (this.panel = el)}
            appendTo={this.props.appendTo}
            panelStyle={this.props.panelStyle}
            panelClassName={this.props.panelClassName}
            scrollHeight={this.props.scrollHeight}
            filter={filterElement}
            onClick={this.onPanelClick}
          >
            {items}
          </DropdownPanel>
        </CSSTransition>
      </div>
    );
  }
}

export default AutoresizeDropdown;
