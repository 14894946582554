import React, { Component } from "react";

import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { registerUser } from "../../actions/auth";
import { addMessage } from "../../actions/ui";

import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Captcha } from "primereact/captcha";

class Register extends Component {
  state = {
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    password: "",
    password2: "",
    captchaValid: false,
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const {
      first_name,
      last_name,
      username,
      email,
      password,
      password2,
    } = this.state;
    if (password === password2 && this.state.captchaValid) {
      this.props.registerUser(first_name, last_name, username, email, password);
    } else {
      console.log("password don't match");
      this.props.addMessage(
        "Error",
        "An error occured while registering.",
        "error"
      );
      this.setState({
        password2_errors: ["The passwords you entered do not match."],
      });
    }
  };

  showResponse = (response) => {
    this.setState({ captchaValid: true });
    //grecaptcha.getResponse(response);
  };

  render() {
    const { isAuthenticated } = this.props.auth;

    let first_name_errors = this.props.auth.errors.first_name || [];
    let last_name_errors = this.props.auth.errors.last_name || [];
    let username_errors = this.props.auth.errors.username || [];
    let email_errors = this.props.auth.errors.email || [];
    let password_errors = this.props.auth.errors.password || [];
    let password2_errors = this.state.password2_errors || [];

    console.log(this.props);

    if (isAuthenticated) {
      return <Redirect to="/" />;
    }
    const {
      first_name,
      last_name,
      username,
      email,
      password,
      password2,
    } = this.state;
    return (
      <div className="col-md-4 m-auto">
        <div className="card mt-4 p-4">
          <form onSubmit={this.onSubmit}>
            <div className="p-fluid p-formgrid p-grid">
              <div className="p-field p-col">
                <label htmlFor="first_name">First name</label>
                <InputText
                  id="first_name"
                  name="first_name"
                  placeholder="First Name"
                  onChange={this.handleChange}
                  value={first_name}
                />
                <small id="first_name_error" className="p-invalid p-d-block">
                  <ul>
                    {first_name_errors.map((el) => (
                      <li key={el.toString()}>{el}</li>
                    ))}
                  </ul>
                </small>
              </div>
              <div className="p-field p-col">
                <div className="p-field p-col">
                  <label htmlFor="last_name">Last name</label>
                  <InputText
                    id="last_name"
                    name="last_name"
                    placeholder="Last Name"
                    onChange={this.handleChange}
                    value={last_name}
                  />
                  <small id="last_name_error" className="p-invalid p-d-block">
                    <ul>
                      {last_name_errors.map((el) => (
                        <li key={el.toString()}>{el}</li>
                      ))}
                    </ul>
                  </small>
                </div>
              </div>
            </div>

            <div className="p-fluid">
              <div className="p-field">
                <label htmlFor="username">Username</label>
                <InputText
                  id="username"
                  name="username"
                  placeholder="Enter Username"
                  onChange={this.handleChange}
                  value={username}
                  className={username_errors.length > 0 ? "p-invalid" : ""}
                />
                <small id="username-help">Username must be unique.</small>
                <small id="username_error" className="p-invalid p-d-block">
                  <ul>
                    {username_errors.map((el) => (
                      <li key={el.toString()}>{el}</li>
                    ))}
                  </ul>
                </small>
              </div>
            </div>

            <div className="p-fluid">
              <div className="p-field">
                <label htmlFor="email">Email</label>
                <InputText
                  id="email"
                  name="email"
                  className={email_errors.length > 0 ? "p-invalid" : ""}
                  placeholder="Enter Email"
                  onChange={this.handleChange}
                  value={email}
                />
                <small id="language-help">
                  We use your e-mail to verify your identity. Only questions
                  concerning accounts coming from this e-mail adress will be
                  answered. It will not be shared with third parties.
                </small>
                <small id="email_error" className="p-invalid p-d-block">
                  <ul>
                    {email_errors.map((el) => (
                      <li key={el.toString()}>{el}</li>
                    ))}
                  </ul>
                </small>
              </div>
            </div>

            <div className="p-fluid">
              <div className="p-field">
                <label htmlFor="password">Password</label>
                <Password
                  id="password"
                  name="password"
                  placeholder="Password"
                  className={password_errors.length > 0 ? "p-invalid" : ""}
                  onChange={this.handleChange}
                  value={password}
                />
                <small id="password-error" className="p-invalid p-d-block">
                  <ul>
                    {password_errors.map((el) => (
                      <li key={el.toString()}>{el}</li>
                    ))}
                  </ul>
                </small>
              </div>
            </div>
            <div className="p-fluid">
              <div className="p-field">
                <label htmlFor="password">Confirm Password</label>
                <Password
                  id="password2"
                  name="password2"
                  placeholder="Password"
                  className={password2_errors.length > 0 ? "p-invalid" : ""}
                  onChange={this.handleChange}
                  value={password2}
                />
                <small id="password2-error" className="p-invalid p-d-block">
                  <ul>
                    {password2_errors.map((el) => (
                      <li key={el.toString()}>{el}</li>
                    ))}
                  </ul>
                </small>
              </div>
            </div>
            <div className="p-fluid p-py-2">
              <Captcha
                siteKey="6LcTUPIZAAAAANk4DON8EyiTFub6d9qZOm7TCTgB"
                onResponse={() => this.setState({ captchaValid: true })}
                onExpire={() => this.setState({ captchaValid: false })}
              ></Captcha>
              <small>
                {this.state.captchaValid ? "" : "The captcha is required."}
              </small>
            </div>
            <Button
              type="submit"
              label="Register"
              className="btn-block"
            ></Button>
          </form>
          <div className="text-center">
            <small className="form-text text-muted">
              Already have an account? <Link to="/login">Login Here</Link>
            </small>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  registerUser,
  addMessage,
})(Register);
