import React, { Component } from "react";
import { connect } from "react-redux";
import { saveProfile, saveUser, changePassword } from "../../actions/auth";

import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

import { LANGUAGES, SPECIALTIES } from "../../config";
import { MultiSelect } from "primereact/multiselect";

export class Profile extends Component {
  state = {
    user: {},
    languageOptions: [],
    specialtyOptions: [],
    old_password: "",
    new_password: "",
  };

  constructor(props) {
    super(props);
    //save thing in state and update, or it gets sluggish if you need to call an update ajax call every keypress

    this.state.user = this.props.auth.user;
    this.handleUserChange = this.handleUserChange.bind(this);
    this.handleProfileChange = this.handleProfileChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onPasswordChangeSubmit = this.onPasswordChangeSubmit.bind(this);

    this.state.languageOptions.push({ value: "", label: "No language" });
    for (const [key, value] of Object.entries(LANGUAGES)) {
      this.state.languageOptions.push({ value: key, label: value.name });
    }

    SPECIALTIES.forEach((el) => {
      this.state.specialtyOptions.push({ value: el.code, label: el.name });
    });
  }

  handleUserChange(field, value) {
    let { user } = this.state;
    user[field] = value;
    this.setState({
      user: user,
    });
  }

  handleProfileChange(field, value) {
    let { profile } = this.state.user;
    profile[field] = value;

    this.setState({
      user: {
        ...this.state.user,
        profile: profile,
      },
    });
  }

  onSubmit(e) {
    e.preventDefault();
    //call update
    this.props.userInputChangeHandler(this.state.user);
    this.props.profileInputChangeHandler(this.state.user.profile);
  }

  onPasswordChangeSubmit(e) {
    e.preventDefault();
    this.props.passwordChangeHandler(
      this.state.old_password,
      this.state.new_password
    );
  }

  render() {
    const { first_name, last_name, username, email } = this.state.user;
    let old_password_errors = this.props.auth.errors.old_password || [];
    let new_password_errors = this.props.auth.errors.new_password || [];

    console.log(old_password_errors);
    return (
      <div
        className="col-md-4 m-auto"
        style={{ justifySelf: "center", alignSelf: "center" }}
      >
        <h1>Welcome {username},</h1>
        <div className="card mt-4 p-4">
          <form onSubmit={this.onSubmit}>
            {
              //<div className="p-fluid">
              //<div className="p-field">
              //  <label htmlFor="username">Username</label>
              //  <InputText
              //    id="username"
              //    name="username"
              //    value={username}
              //    placeholder="Username"
              //    disabled
              //  />
              //</div>
              //</div>
            }
            <h5>Profile info &amp; Preferences</h5>
            <div className="p-fluid p-formgrid p-grid">
              <div className="p-field p-col">
                <label htmlFor="first_name">First name</label>
                <InputText
                  id="first_name"
                  name="first_name"
                  placeholder="First Name"
                  value={first_name}
                  onChange={(e) =>
                    this.handleUserChange(e.target.name, e.target.value)
                  }
                />
              </div>
              <div className="p-field p-col">
                <div className="p-field p-col">
                  <label htmlFor="last_name">Last name</label>
                  <InputText
                    id="last_name"
                    name="last_name"
                    placeholder="Last Name"
                    value={last_name}
                    onChange={(e) =>
                      this.handleUserChange(e.target.name, e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
            <div className="p-fluid">
              <div className="p-field">
                <label htmlFor="email">Email</label>
                <InputText
                  id="email"
                  name="email"
                  value={email}
                  placeholder="Email"
                  onChange={(e) =>
                    this.handleUserChange(e.target.name, e.target.value)
                  }
                />
                <small id="language-help">
                  We use your e-mail to verify your identity. Only questions
                  concerning accounts coming from this e-mail adress will be
                  answered. It will not be shared with third parties.
                </small>
              </div>
            </div>
            <div className="p-fluid">
              <div className="p-field">
                <label htmlFor="language">Prefered language</label>
                <Dropdown
                  id="language"
                  aria-describedby="language-help"
                  options={this.state.languageOptions}
                  value={this.state.user.profile.language}
                  filter={true}
                  panelStyle={{ maxWidth: "100%" }}
                  placeholder="Your prefered language."
                  onChange={(e) => {
                    this.handleProfileChange("language", e.value);
                  }}
                />
                <small id="language-help">
                  This is used to automaticaly configure the language filter in
                  the gallery.
                </small>
              </div>
            </div>
            <div className="p-fluid">
              <div className="p-field">
                <label htmlFor="fields_of_interest">Fields of interest</label>
                <MultiSelect
                  id="fields_of_interest"
                  aria-describedby="fields_of_interest-help"
                  options={this.state.specialtyOptions}
                  value={this.state.user.profile.fields_of_interest}
                  filter={true}
                  panelStyle={{ maxWidth: "100%" }}
                  placeholder="Your fields of interest"
                  onChange={(e) => {
                    this.handleProfileChange("fields_of_interest", e.value);
                  }}
                />
                <small id="fields_of_interest-help">
                  This helps us to focus further development of ClinTextGent. In
                  the future this will be used to automaticaly configure the
                  gallery in the same way as with the prefered language.
                </small>
              </div>
            </div>
            <Button type="submit" label="Save" className="btn-block"></Button>
          </form>
        </div>

        <div className="card mt-4 p-4">
          <form onSubmit={this.onPasswordChangeSubmit}>
            <h5>Change password</h5>
            <div className="p-fluid p-formgrid p-grid">
              <div className="p-field p-col">
                <label htmlFor="old_password">Old password</label>
                <Password
                  id="old_password"
                  name="old_password"
                  placeholder="Current password"
                  value={this.state.old_password}
                  className={old_password_errors.length > 0 ? "p-invalid" : ""}
                  onChange={(e) =>
                    this.setState({ old_password: e.target.value })
                  }
                />
                <small id="old_password-error" className="p-invalid p-d-block">
                  <ul>
                    {old_password_errors.map((el) => (
                      <li key={el.toString()}>{el}</li>
                    ))}
                  </ul>
                </small>
              </div>
              <div className="p-field p-col">
                <div className="p-field p-col">
                  <label htmlFor="new_password">New password</label>
                  <Password
                    id="new_password"
                    name="new_password"
                    placeholder="New password"
                    value={this.state.new_password}
                    className={
                      new_password_errors.length > 0 ? "p-invalid" : ""
                    }
                    onChange={(e) =>
                      this.setState({ new_password: e.target.value })
                    }
                  />
                  <small
                    id="old_password-error"
                    className="p-invalid p-d-block"
                  >
                    <ul>
                      {new_password_errors.map((el) => (
                        <li key={el.toString()}>{el}</li>
                      ))}
                    </ul>
                  </small>
                </div>
              </div>
            </div>
            <Button type="submit" label="Submit" className="btn-block"></Button>
          </form>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = function (dispatch, ownProps) {
  return {
    userInputChangeHandler: function (user) {
      dispatch(saveUser(user));
    },
    profileInputChangeHandler: function (profile) {
      dispatch(saveProfile(profile));
    },
    passwordChangeHandler: function (old_password, new_password) {
      dispatch(changePassword(old_password, new_password));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
