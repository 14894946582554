//Clindocs
export const CHANGE_CLINDOC = "clindocChanged";
export const CHANGE_VALUE = "valueChanged";
export const REMOVE_FINDING = "findingRemoved";
export const CHANGE_LINE_SELECTION = "lineSelectionChanged";
export const TOGGLE_ALL_LINES = "allLinesToggled";
//ajax get clindocs
export const FETCH_CLINDOCS_LOAD = "clindocsFetchLoaded";
export const FETCH_CLINDOCS_SUCCESS = "clindocsFetched";
export const FETCH_CLINDOCS_ERROR = "clindocsFetchError";

//UNDO/REDO
export const CLINDOC_UNDO = "clindocUndone";
export const CLINDOC_REDO = "clindocRedone";

//ajax save clindoc after editing
export const SAVE_CLINDOC_LOAD = "clindocSaveLoaded";
export const SAVE_CLINDOC_SUCCESS = "clindocSaved";
export const SAVE_CLINDOC_ERROR = "clindocSaveError";
export const ADD_CLINDOC_TO_LIST = "clinDocAddedToList";

//delete clindoc
export const DELETE_CLINDOC_LOAD = "clindocDeleteLoaded";
export const DELETE_CLINDOC_SUCCESS = "clindocDeleted";
export const DELETE_CLINDOC_ERROR = "clindocDeleteError";
export const REMOVE_CLINDOC_FROM_LIST = "clinDocRemovedFromList";

//update editor id (e.g. after saving)
//export const UPDATE_EDITOR_CLINDOC = "editorClinDocUpdated";
export const CHANGE_EDITOR_CLINDOC = "editorClinDocChanged";
export const CHANGE_EDITOR_NAME = "editorClinDocNameChanged";
export const CHANGE_EDITOR_DESCRIPTION = "editorClinDocDescChanged";
export const CHANGE_EDITOR_STRING = "editorClinDocInputStringChanged";
export const CHANGE_EDITOR_ID = "editorClinDocIdChanged";
export const NEW_EDITOR_CLINDOC = "editorClinDocNew";
export const EDITOR_CLINDOC_CHANGE_UNSAVED_CHANGES =
  "editorClinDocUnsavedChangesChanged";

//authentication & registering
export const USER_LOADING = "userLoading";
export const USER_LOADED = "userLoaded";
export const AUTH_ERROR = "authenticationError";
export const LOGIN_SUCCESS = "loginSuccess";
export const LOGIN_FAIL = "loginFailed";
export const LOGOUT = "loggedOut";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

//update profile
export const PROFILE_UPDATE_LOADING = "profileUpdateLoaded";
export const PROFILE_UPDATE_SUCCESS = "profileUpdated";
export const PROFILE_UPDATE_ERROR = "profileUpdateError";

//update user
export const USER_UPDATE_LOADING = "userUpdateLoaded";
export const USER_UPDATE_SUCCESS = "userUpdated";
export const USER_UPDATE_ERROR = "userUpdateError";

//change password
export const CHANGE_PASSWORD_LOADING = "userUpdateLoaded";
export const CHANGE_PASSWORD_SUCCESS = "userUpdated";
export const CHANGE_PASSWORD_ERROR = "userUpdateError";

//messages
export const ADD_MESSAGE = "messageAdded";
export const REMOVE_MESSAGE = "messageRemoved";

//loading
export const START_LOADING = "loadingStarted";
export const STOP_LOADING = "loadingStopped";

//cookies
export const UPDATE_COOKIE_CONSENT = "cookieConsentUpdated";

//gallery
export const GALLERY_DELETE_CLINDOC_LOAD = "galleryClinDocDeleteLoaded";
export const GALLERY_DELETE_CLINDOC_ERROR = "galleryClinDocDeleteError";
export const GALLERY_DELETE_CLINDOC_SUCCESS = "galleryClinDocDeleted";

export const GALLERY_SAVE_CLINDOC_LOAD = "galleryClinDocAddLoaded";
export const GALLERY_SAVE_CLINDOC_SUCCESS = "galleryClinDocAdded";
export const GALLERY_SAVE_CLINDOC_ERROR = "galleryClinDocAddError";

export const GALLERY_NEW_CLINDOC = "galleryClinDocNew";
export const GALLERY_CLINDOC_DETAILS_CHANGE = "galleryClinDocDetailsChanged";

export const GALLERY_CHANGE_CLINDOC_RATING_LOAD =
  "galleryClinDocRatingChangeLoaded";
export const GALLERY_CHANGE_CLINDOC_RATING_ERROR =
  "galleryClinDocRatingChangeError";
export const GALLERY_CHANGE_CLINDOC_RATING_SUCCESS =
  "galleryClinDocRatingChanged";

//import is handled via SAVE_CLINDOC_(LOAD/SAVE/SUCCESS)
export const GALLERY_IMPORT_CLINDOC_LOAD = "galleryClinDocImportLoaded";
export const GALLERY_IMPORT_CLINDOC_ERROR = "galleryClinDocImportError";
export const GALLERY_IMPORT_CLINDOC_SUCCESS = "galleryClinDocImported";

//fetch
export const GALLERY_FETCH_CLINDOCS_LOAD = "galleryClinDocsFetchLoaded";
export const GALLERY_FETCH_CLINDOCS_ERROR = "galleryClinDocsFetchError";
export const GALLERY_FETCH_CLINDOCS_SUCCESS = "galleryClinDocsFetched";

export const GALLERY_FETCH_CLINDOC_DETAILS_SUCCESS =
  "galleryClinDocDetailsFetched";
export const GALLERY_FETCH_CLINDOC_DETAILS_LOAD =
  "galleryFetchClinDocDetailsLoaded";
export const GALLERY_FETCH_CLINDOC_DETAILS_ERROR =
  "galleryFetchClinDocDetailsError";
