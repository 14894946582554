import React, { Component } from "react";
import { connect } from "react-redux";

import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Rating } from "primereact/rating";

//relative imports
import ClinDocDisplayPanel from "../../components/clinDocDisplayPanel";
import { importClinDoc, changeRating } from "../../actions/gallery";

import { DiscussionEmbed } from "disqus-react";

class GalleryClinDocPreviewPanel extends Component {
  state = {};

  render() {
    const leftContents = (
      <React.Fragment>
        <Button
          label="Import to App"
          icon="pi pi-download"
          className="p-mr-2 p-button-help"
          onClick={() => {
            this.props.saveAsClinDoc(this.props.clinDoc);
            this.props.onHide();
          }}
        />
        <i className="p-toolbar-separator p-mr-4" />
        <div className="p-p-2">
          <Rating
            stars={5}
            cancel={false}
            value={this.props.clinDoc.rating || 0}
            onChange={(e) => {
              this.props.setRating(this.props.clinDoc, e.value);
            }}
          />
        </div>
      </React.Fragment>
    );

    const rightContents = (
      <React.Fragment>
        <Button
          label="Close"
          icon="pi pi-times"
          className="p-mr-2"
          onClick={() => this.props.onHide()}
        />
      </React.Fragment>
    );
    const footer = <Toolbar left={leftContents} right={rightContents} />;

    if (this.props.loading) {
      return <span></span>; //<ProgressSpinner />;
    } else {
      return (
        <Dialog
          header="Preview document"
          visible={this.props.visible}
          maximizable={true}
          modal
          style={{ minWidth: "500px", width: "50vw" }}
          footer={footer}
          onHide={this.props.onHide}
        >
          <ClinDocDisplayPanel
            description={this.props.clinDoc.description || ""}
            inputString={this.props.clinDoc.inputString || ""}
          />
          <DiscussionEmbed
            shortname="clintextgen"
            config={{
              url: "clintextgen.io",
              identifier: toString(this.props.clinDoc.id),
              title:
                this.props.clinDoc.name +
                " | " +
                this.props.clinDoc.description,
              language: this.props.clinDoc.language, //e.g. for Traditional Chinese (Taiwan)
            }}
          />
        </Dialog>
      );
    }
  }
}

const mapStateToProps = function (store) {
  return {
    clinDoc: store.galleryState.selectedClinDoc,
    loading: store.galleryState.loading,
  };
};

const mapDispatchToProps = function (dispatch, ownProps) {
  return {
    saveAsClinDoc: function (document) {
      //mark as new document by setting id to 0
      dispatch(importClinDoc({ ...document, id: 0 }));
    },
    setRating: function (document, value) {
      //dispatch action, still needs to be written.
      //note: action needs to update clindoc rating field!
      dispatch(changeRating(document, value));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GalleryClinDocPreviewPanel);
