import React, { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Fieldset } from "primereact/fieldset";
import { Dropdown } from "primereact/dropdown";

//relative imports
import ClinDocDisplayPanel from "../clinDocDisplayPanel";
import ClinDocEditorPanel from "../../components/clinDocEditorPanel";
import * as helper from "../helperFunctions";
import * as galleryActions from "../../actions/gallery";

class GalleryClinDocFormPanel extends Component {
  state = {
    stringPanelCollapsed: true,
    selectedBaseDocument: {},
  };

  static propTypes = {
    baseZIndex: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.renderForm = this.renderForm.bind(this);
    this.renderInputStringEditor = this.renderInputStringEditor.bind(this);
  }

  renderInputStringEditor() {
    const renderClindocPanel = () => {
      let panel = (
        <ClinDocDisplayPanel
          findings={this.state.findings}
          description={this.props.selectedClinDoc.description}
          inputString={this.props.selectedClinDoc.inputString}
        />
      );

      if (this.props.selectedClinDoc.inputString === "") {
        panel = (
          <p>
            Start typing the document string in the editor above to show a
            preview.{" "}
          </p>
        );
      }

      return panel;
    };

    return (
      <Fieldset
        legend="Edit document generation string"
        toggleable
        collapsed={this.state.stringPanelCollapsed}
        onToggle={(e) => this.setState({ stringPanelCollapsed: e.value })}
      >
        <div className="p-grid">
          <div className="p-col-12">
            <ClinDocEditorPanel
              baseZIndex={10}
              menuBarPrepend={[]}
              menuBarAppend={[]}
              inputString={this.props.selectedClinDoc.inputString}
              onSetUnsavedChanges={() => {}}
              onChangeClinDocInputString={(value) => {
                this.props.onFieldChange("inputString", value);
              }}
            />
          </div>
        </div>
        <h2>Preview </h2>
        {renderClindocPanel()}
      </Fieldset>
    );
  }

  renderForm() {
    const isNewClinDoc = this.props.selectedClinDoc.id === 0;

    const clinDocListTemplate = (option) => {
      return (
        <div>
          <span className="p-text-bold">{option.name}</span> |{" "}
          <span style={{ fontSize: "0.875rem" }}>{option.description}</span>
        </div>
      );
    };

    const selectedClinDocTemplate = (option, props) => {
      if (option) {
        return (
          <div>
            <span className="p-text-bold">{option.name}</span> |{" "}
            <span style={{ fontSize: "0.875rem" }}>{option.description}</span>
          </div>
        );
      }

      return <span>{props.placeholder}</span>;
    };

    return (
      <div className=" p-m-2">
        <div className="p-grid">
          <div className="p-col-12">
            <h2>Editor</h2>
            {isNewClinDoc ? (
              <h4>New clinical document</h4>
            ) : (
              <h4>Editing {this.props.selectedClinDoc.name}</h4>
            )}
          </div>
        </div>
        <div className="p-fluid">
          {isNewClinDoc ? (
            <div className="p-field">
              <label htmlFor="base_document">Base Document </label>
              <Dropdown
                id="base_document"
                aria-describedby="base_document-help"
                options={this.props.clinDocs}
                value={this.state.selectedBaseDocument}
                filter={true}
                optionLabel="name"
                placeholder="Choose a document to add"
                itemTemplate={clinDocListTemplate}
                valueTemplate={selectedClinDocTemplate}
                onChange={(e) => {
                  console.log(e);
                  this.setState({ selectedBaseDocument: e.value });
                  this.props.onFieldChange("inputString", e.value.inputString);
                  this.props.onFieldChange("name", e.value.name);
                  this.props.onFieldChange("description", e.value.description);
                }}
              />
              <small id="base_document-help">
                The document to publish to the Gallery
              </small>
            </div>
          ) : (
            ""
          )}
          <div className="p-field">
            <label htmlFor="name" className="p-d-block">
              Document Name
            </label>
            <InputText
              id="name"
              aria-describedby="name-help"
              value={this.props.selectedClinDoc.name}
              className="p-d-block"
              placeholder="Enter a name"
              onChange={(e) => {
                this.props.onFieldChange("name", e.target.value);
              }}
            />
            <small id="name-help" className="p-d-block">
              The name of the document, this field is required
            </small>
          </div>
          <div className="p-field">
            <label htmlFor="description">Document description</label>
            <InputText
              id="description"
              aria-describedby="description-help"
              value={this.props.selectedClinDoc.description}
              placeholder="Enter a description"
              onChange={(e) => {
                this.props.onFieldChange("description", e.target.value);
              }}
            />
            <small id="description-help">
              A description of the document, this field is required
            </small>
          </div>
          <div className="p-field">
            <label htmlFor="language">Document language</label>
            <Dropdown
              id="language"
              aria-describedby="language-help"
              options={this.props.languageOptions}
              value={this.props.selectedClinDoc.language}
              filter={true}
              placeholder="Define the document's language"
              onChange={(e) => {
                this.props.onFieldChange("language", e.target.value);
              }}
            />
            <small id="language-help">
              The language of the document, this field is required
            </small>
          </div>
          {isNewClinDoc ? "" : this.renderInputStringEditor()}
        </div>
      </div>
    );
  }
  render() {
    if (this.props.loading) {
      //return <ProgressSpinner />;
      return <React.Fragment />;
    } else {
      return (
        <Dialog
          header="Edit Gallery Document"
          visible={this.props.visible}
          modal
          maximizable={true}
          style={{ minWidth: "500px", width: "50vw" }}
          footer={helper.renderFooter(
            this.props.onHide,
            () => {
              this.props.saveGalleryClinDoc(this.props.selectedClinDoc);
              this.props.onHide();
            },
            "Save",
            "Cancel"
          )}
          onHide={() => {
            this.setState({ selectedBaseDocument: {} });
            this.props.onHide();
          }}
        >
          {this.renderForm()}
        </Dialog>
      );
    }
  }
}

const mapStateToProps = function (store) {
  return {
    selectedClinDoc: store.galleryState.selectedClinDoc,
    clinDocs: store.clinDocState.present.clindocs,
    loading: store.galleryState.loading,
  };
};

const mapDispatchToProps = function (dispatch, ownProps) {
  return {
    saveGalleryClinDoc: function (document) {
      dispatch(galleryActions.saveGalleryClinDoc(document));
    },
    onFieldChange: function (fieldName, value) {
      dispatch(
        galleryActions.changeGalleryClinDocDetailField(fieldName, value)
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GalleryClinDocFormPanel);
