import React, { Component } from "react";

import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { renderFooter } from "../components/helperFunctions";

class AddCheckBoxModal extends Component {
  state = {
    name: "",
    true_text: "",
    false_text: "",
  };

  constructor(props) {
    super(props);
    this.handleSave = this.handleSave.bind(this);
  }

  handleSave() {
    let { name, true_text, false_text } = this.state;
    this.setState({ name: "", true_text: "", false_text: "" });
    let findingString =
      "#CB|" + name + "|" + true_text + "|" + false_text + "#";
    this.props.onSave(findingString);
  }

  render() {
    return (
      <Dialog
        header="Add Checkbox"
        visible={this.props.show}
        modal
        style={{ minWidth: "450px", width: "50vw" }}
        footer={renderFooter(
          this.props.hide,
          (e) => {
            this.handleSave(e);
            this.props.hide();
          },
          "Add",
          "Cancel"
        )}
        onHide={this.props.hide}
      >
        <div className="confirmation-content">
          <div className="p-fluid">
            <div className="p-field">
              <label htmlFor="name" className="p-d-block">
                Value
              </label>
              <InputText
                id="name"
                aria-describedby="name-help"
                value={this.state.name}
                className="p-d-block"
                placeholder="Checkbox name"
                onChange={(e) => {
                  this.setState({
                    name: e.target.value,
                  });
                }}
              />
              <small id="name" className="p-d-block">
                The text displayed after the checkbox in the display mode.
              </small>
            </div>
            <div className="p-field">
              <label htmlFor="true_text" className="p-d-block">
                Checked value
              </label>
              <InputText
                id="true_text"
                aria-describedby="true_text-help"
                value={this.state.true_text}
                className="p-d-block"
                placeholder="Value if checkbox is checked as true"
                onChange={(e) => {
                  this.setState({
                    true_text: e.target.value,
                  });
                }}
              />
              <small id="true_text" className="p-d-block">
                The text that is inserted into the output field when the
                checkbox is checked as true.
              </small>
            </div>
            <div className="p-field">
              <label htmlFor="false_text" className="p-d-block">
                Unchecked value
              </label>
              <InputText
                id="false_text"
                aria-describedby="false_text-help"
                value={this.state.false_text}
                className="p-d-block"
                placeholder="Value if checkbox is checked as false"
                onChange={(e) =>
                  this.setState({
                    false_text: e.target.value,
                  })
                }
              />
              <small id="false_text-help" className="p-d-block">
                The text that is inserted into the output field when the
                checkbox is checked as false.
              </small>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default AddCheckBoxModal;
