import axios from "axios";
import * as actions from "../actions/types";

import * as app_config from "../config";
import { drfReduxApiCall } from "../components/helperFunctions";

import {
  pendingTask, // The action key for modifying loading state
  begin, // The action value if a "long" running task begun
  end, // The action value if a "long" running task ended
  //endAll, // The action value if all running tasks must end
} from "react-redux-spinner";

// axios.defaults.withCredentials = true;

export const loadUser = () => (dispatch, getState) => {
  dispatch({ type: actions.USER_LOADING, [pendingTask]: begin });

  const token = getState().auth.token;

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (token) {
    config.headers["Authorization"] = `JWT ${token}`;
  }

  axios
    .get(app_config.API_URL + "api/auth/user/", config)
    .then((res) => {
      dispatch({
        type: actions.USER_LOADED,
        [pendingTask]: end,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        [pendingTask]: end,
        type: actions.AUTH_ERROR,
      });
    });
};

export const loginUser = (username, password) => (dispatch, getState) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ username, password });

  axios
    .post(app_config.API_URL + "api/auth/token/", body, config)
    .then((res) => {
      dispatch({
        type: actions.LOGIN_SUCCESS,
        payload: res.data,
      });
    })
    .catch((error) => {
      const payload = {};
      if (error.response) {
        payload.errors = error.response.data;
      }
      dispatch({
        type: actions.LOGIN_FAIL,
        payload: payload,
      });
    });
};

export const logoutUser = () => (dispatch, getState) => {
  localStorage.removeItem("token");
  dispatch({
    type: actions.LOGOUT,
  });
};

export const registerUser = (
  first_name,
  last_name,
  username,
  email,
  password
) => (dispatch, getState) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    first_name,
    last_name,
    username,
    email,
    password,
  });

  axios
    .post(app_config.API_URL + "api/auth/register/", body, config)
    .then((res) => {
      dispatch({
        type: actions.REGISTER_SUCCESS,
        payload: res.data,
      });
    })
    .catch((error) => {
      const payload = {};
      if (error.response) {
        payload.errors = error.response.data;
      }

      dispatch({
        type: actions.REGISTER_FAIL,
        payload: payload,
      });

      dispatch({
        type: actions.ADD_MESSAGE,
        payload: {
          severity: "error",
          summary: "Error",
          detail: "An error occured while registering.",
        },
      });
    });
};

export const saveUser = (user) => (dispatch, getState) => {
  const saveUserLoading = () =>
    dispatch({
      type: actions.USER_UPDATE_LOADING,
      [pendingTask]: begin,
    });

  const saveUserSuccess = (res) => {
    console.log(res.data);
    dispatch({
      type: actions.USER_UPDATE_SUCCESS,
      [pendingTask]: end,
      payload: res.data,
    });
  };

  const saveUserError = (error) => {
    dispatch({
      type: actions.USER_UPDATE_ERROR,
      [pendingTask]: end,
      payload: {
        error: error,
      },
    });

    dispatch({
      type: actions.ADD_MESSAGE,
      payload: {
        severity: "error",
        summary: "Error",
        detail: "An error occured while saving.",
      },
    });
  };

  const token = getState().auth.token;
  const api_url = "api/auth/user/";
  const method = "put";

  drfReduxApiCall(
    api_url,
    method,
    token,
    user,
    saveUserLoading,
    saveUserSuccess,
    saveUserError
  );
};

export const saveProfile = (userProfile) => (dispatch, getState) => {
  const saveProfileLoading = () =>
    dispatch({
      type: actions.PROFILE_UPDATE_LOADING,
      [pendingTask]: begin,
    });

  const saveProfileSuccess = (res) => {
    dispatch({
      type: actions.PROFILE_UPDATE_SUCCESS,
      [pendingTask]: end,
      payload: res.data,
    });
  };

  const saveProfileError = (error) => {
    dispatch({
      type: actions.PROFILE_UPDATE_ERROR,
      [pendingTask]: end,
      payload: {
        error: error,
      },
    });

    dispatch({
      type: actions.ADD_MESSAGE,
      payload: {
        severity: "error",
        summary: "Error",
        detail: "An error occured while saving.",
      },
    });
  };

  const token = getState().auth.token;
  const api_url = "api/auth/profile/";
  const method = "put";

  drfReduxApiCall(
    api_url,
    method,
    token,
    userProfile,
    saveProfileLoading,
    saveProfileSuccess,
    saveProfileError
  );
};

export const changePassword = (old_password, new_password) => (
  dispatch,
  getState
) => {
  const changePasswordLoading = () =>
    dispatch({
      type: actions.CHANGE_PASSWORD_LOADING,
      [pendingTask]: begin,
    });

  const changePasswordSuccess = (res) => {
    dispatch({
      type: actions.CHANGE_PASSWORD_SUCCESS,
      [pendingTask]: end,
    });

    dispatch({
      type: actions.ADD_MESSAGE,
      payload: {
        severity: "success",
        summary: "Success!",
        detail: "Password changed!",
      },
    });
  };

  const changePasswordError = (error) => {
    const payload = {};
    if (error.response) {
      payload.errors = error.response.data;
    }

    dispatch({
      type: actions.CHANGE_PASSWORD_ERROR,
      [pendingTask]: end,
      payload: payload,
    });

    dispatch({
      type: actions.ADD_MESSAGE,
      payload: {
        severity: "error",
        summary: "Error",
        detail: "An error occured while changing the password.",
      },
    });
  };

  const token = getState().auth.token;
  const api_url = "api/auth/change_password/";
  const method = "put";

  drfReduxApiCall(
    api_url,
    method,
    token,
    { old_password: old_password, new_password: new_password },
    changePasswordLoading,
    changePasswordSuccess,
    changePasswordError
  );
};
