import React, { Component } from "react";
import { connect } from "react-redux";
import * as ui_actions from "../../actions/ui";

import PropTypes from "prop-types";

import { Sidebar } from "primereact/sidebar";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { COOKIE_CONSENT } from "../../config";
import { Link } from "react-router-dom";

const mapActions = (dispatch) => ({
  closeCookies: (consent) => dispatch(ui_actions.updateCookieSettings(consent)),
  loadCookies: () => dispatch(ui_actions.loadCookieSettings()),
});

const mapStateToProps = function (store) {
  return {
    cookies: store.uIState.cookies,
  };
};

class GDPRSettingsPanel extends Component {
  static propTypes = {
    renderAsPanel: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      render: 0,
      cookies: {},
      visible: true,
      allowRender: false,
    };

    this.handleAcceptAll = this.handleAcceptAll.bind(this);
    this.handleValidate = this.handleValidate.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  incRender() {
    this.setState({ render: this.state.render + 1 });
  }

  componentDidMount() {
    this.props.loadCookies();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //cookies have loaded
    if (
      this.props.cookies.hasLoaded &&
      prevProps.cookies.hasLoaded !== this.props.cookies.hasLoaded
    ) {
      this.setState(
        {
          cookies: this.props.cookies.consentActive,
          //cookies have been already set or force render....
          allowRender:
            !this.props.cookies.consentStored || this.props.renderAsPanel,
        },
        () => {
          this.incRender();
        }
      );
    }

    //if cookies have changed, rerender.
    if (
      JSON.stringify(this.props.cookies.consentActive) !==
      JSON.stringify(prevProps.cookies.consentActive)
    ) {
      this.setState({
        cookies: this.props.cookies.consentActive,
      });
    }
  }

  handleToggle = (e) => {
    //cookiesTool.setActive(id, value);
    this.setState({ cookies: { ...this.state.cookies, [e.value]: e.checked } });
    this.incRender();
  };

  handleValidate = () => {
    this.props.closeCookies({ ...this.state.cookies });
  };

  handleAcceptAll = () => {
    let cookies = {};
    Object.keys(COOKIE_CONSENT).forEach(function (index) {
      cookies[index] = true;
    });
    this.props.closeCookies(cookies);
  };

  renderContent = () => {
    return (
      <React.Fragment>
        <div className="p-pt-5">
          <h6>GDPR &amp; cookie Settings</h6>
          <div className="p-grid">
            <div className="p-formgroup-inline p-col-9 p-pt-3">
              <div className="p-field">
                <Checkbox
                  name="obligatory"
                  value="obligatory"
                  //onChange={this.onCityChange}
                  checked={this.props.cookies.consentActive.obligatory}
                  disabled
                />
                <label htmlFor="obligatory" className="p-checkbox-label p-pl-2">
                  Obligatory Cookies
                </label>
              </div>
              <div className="p-field">
                <Checkbox
                  name="analytics"
                  value="analytics"
                  onChange={this.handleToggle}
                  checked={this.state.cookies.analytics}
                />
                <label htmlFor="analytics" className="p-checkbox-label p-pl-2">
                  analytics
                </label>
              </div>
              <div className="p-field">
                <Checkbox
                  name="targetted_advertising"
                  value="targetted_advertising"
                  onChange={this.handleToggle}
                  checked={this.state.cookies.targetted_advertising}
                />
                <label
                  htmlFor="targetted_advertising"
                  className="p-checkbox-label p-pl-2"
                >
                  Advertising relevance
                </label>
              </div>
              <div className="p-field">
                <Checkbox
                  name="social_media"
                  value="social_media"
                  onChange={this.handleToggle}
                  checked={this.state.cookies.social_media}
                />
                <label
                  htmlFor="social_media"
                  className="p-checkbox-label p-pl-2"
                >
                  Social Media inclusion (e.g. Disqus)
                </label>
              </div>
            </div>
            <div className="p-col-3">
              <div>
                <Button
                  type="button"
                  className="p-button-secondary p-button-text p-button-sm"
                  label="Save Choices"
                  onClick={this.handleValidate}
                />
                <Button
                  type="button"
                  className=" p-button-sm"
                  label={
                    this.props.renderAsPanel
                      ? "Accept all"
                      : "Accept all & close"
                  }
                  onClick={this.handleAcceptAll}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    if (!this.state.allowRender) return <React.Fragment></React.Fragment>;

    if (this.props.renderAsPanel) {
      return this.renderContent();
    } else {
      return (
        <React.Fragment>
          <Sidebar
            visible={!this.props.cookies.consentStored}
            position="bottom"
            className="ui-sidebar-sm"
            closeOnEscape={false}
            blockScroll={true}
            baseZIndex={100}
            dismissable={false}
            showCloseIcon={false}
            onHide={() => {
              this.setState({ visible: false });
            }}
          >
            {this.renderContent()}
            <small>
              Check out the <Link to="/cookies">cookie policy</Link> for more
              details.
            </small>
          </Sidebar>
        </React.Fragment>
      );
    }
  }
}

export default connect(mapStateToProps, mapActions)(GDPRSettingsPanel);
