import * as actions from "./types";
import axios from "axios";
import * as app_config from "../config";

import {
  pendingTask, // The action key for modifying loading state
  begin, // The action value if a "long" running task begun
  end, // The action value if a "long" running task ended
} from "react-redux-spinner";

export const saveClinDoc = (clindoc) => (dispatch, getState) => {
  dispatch({
    type: actions.SAVE_CLINDOC_LOAD,
    [pendingTask]: begin,
  });

  const token = getState().auth.token;

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (token) {
    config.headers["Authorization"] = `JWT ${token}`;
  }

  /*
  const body = JSON.stringify({
    id,
    inputString,
    name,
    description,
  });*/

  let isNewClinDoc = clindoc.id === 0;

  let request;
  if (isNewClinDoc) {
    request = axios.post(app_config.API_URL + "api/clindocs/", clindoc, config);
  } else {
    request = axios.put(
      app_config.API_URL + "api/clindocs/" + clindoc.id + "/", //append slash, important for django
      clindoc,
      config
    );
  }

  request
    .then((res) => {
      dispatch({
        type: actions.SAVE_CLINDOC_SUCCESS,
        [pendingTask]: end,
        payload: res.data,
      });

      //change editor to avoid multiple copies of same document
      dispatch({
        type: actions.CHANGE_EDITOR_CLINDOC,
        payload: { document: res.data },
      });

      dispatch({
        type: actions.EDITOR_CLINDOC_CHANGE_UNSAVED_CHANGES,
        payload: { value: false },
      });

      if (isNewClinDoc) {
        dispatch({
          type: actions.ADD_CLINDOC_TO_LIST,
          payload: { document: res.data },
        });
      }

      let message =
        "Succesfully " + (isNewClinDoc ? "added new " : "saved ") + "document";
      //show success message
      dispatch({
        type: actions.ADD_MESSAGE,
        payload: {
          severity: "success",
          summary: "Success!",
          detail: message,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.SAVE_CLINDOC_ERROR,
        [pendingTask]: end,
        payload: {
          error: error,
        },
      });

      dispatch({
        type: actions.ADD_MESSAGE,
        payload: {
          severity: "error",
          summary: "Error",
          detail: "An error occured while saving",
        },
      });
    });
};

export const deleteClinDoc = (clindoc) => (dispatch, getState) => {
  dispatch({
    type: actions.DELETE_CLINDOC_LOAD,
    [pendingTask]: begin,
  });

  const token = getState().auth.token;
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (token) {
    config.headers["Authorization"] = `JWT ${token}`;
  }

  axios
    .delete(
      app_config.API_URL + "api/clindocs/" + clindoc.id + "/", //append slash, important for django
      config
    )
    .then((res) => {
      dispatch({
        type: actions.DELETE_CLINDOC_SUCCESS,
        [pendingTask]: end,
        payload: { id: clindoc.id },
      });

      //change editor id to avoid multiple copies of same document
      dispatch({
        type: actions.NEW_EDITOR_CLINDOC,
      });

      //change editor id to avoid multiple copies of same document
      dispatch({
        type: actions.EDITOR_CLINDOC_CHANGE_UNSAVED_CHANGES,
        payload: { value: false },
      });

      //remove from list
      dispatch({
        type: actions.REMOVE_CLINDOC_FROM_LIST,
        payload: { id: clindoc.id },
      });

      dispatch({
        type: actions.ADD_MESSAGE,
        payload: {
          severity: "warn",
          summary: "Deleted!",
          detail: "Deleted document " + clindoc.name,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.DELETE_CLINDOC_ERROR,
        [pendingTask]: end,
        payload: {
          error: error,
        },
      });

      dispatch({
        type: actions.ADD_MESSAGE,
        payload: {
          severity: "error",
          summary: "Error",
          detail: "An error occured while deleting...",
        },
      });
    });
};
