import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import GDPRSettingsPanel from "../components/utils/gdprSettingsPanel";

import { Button } from "primereact/button";

export default class Footer extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Container className="p-pt-5">
          <footer>
            <p className="float-right">
              <Button
                label="Back to top"
                className="p-button-link p-button-sm"
                onClick={() => window.scrollTo(0, 0)}
              />
            </p>
            <p>
              © 2020 · <Link to="/cookies">Cookie policy</Link> ·{" "}
              <Link to="/privacy">Privacy policy</Link> ·{" "}
              <Link to="/terms">Terms &amp; Conditions</Link>
            </p>
          </footer>
        </Container>
        <GDPRSettingsPanel />
      </React.Fragment>
    );
  }
}

export class CookiePolicy extends Component {
  render() {
    return (
      <Container className="p-pt-5">
        <h1>Cookie Policy for ClinTextGen</h1>
        <p>
          This is the Cookie Policy for ClinTextGen, accessible from
          https://www.clintextgen.io
        </p>
        <p>
          <strong>What Are Cookies</strong>
        </p>
        <p>
          As is common practice with almost all professional websites this site
          uses cookies, which are tiny files that are downloaded to your
          computer, to improve your experience. This page describes what
          information they gather, how we use it and why we sometimes need to
          store these cookies. We will also share how you can prevent these
          cookies from being stored however this may downgrade or 'break'
          certain elements of the sites functionality.
        </p>
        <p>
          For more general information on cookies, please read{" "}
          <a href="https://www.cookieconsent.com/what-are-cookies/">
            "What Are Cookies"
          </a>
          . Information regarding cookies from this Cookies Policy are from{" "}
          <a href="https://www.generateprivacypolicy.com/">
            the Privacy Policy Generator
          </a>
          .
        </p>
        <p>
          <strong>How We Use Cookies</strong>
        </p>
        <p>
          We use cookies for a variety of reasons detailed below. Unfortunately
          in most cases there are no industry standard options for disabling
          cookies without completely disabling the functionality and features
          they add to this site. It is recommended that you leave on all cookies
          if you are not sure whether you need them or not in case they are used
          to provide a service that you use.
        </p>
        <p>
          <strong>Disabling Cookies</strong>
        </p>
        <p>
          You can prevent the setting of cookies by adjusting the settings on
          your browser (see your browser Help for how to do this). Be aware that
          disabling cookies will affect the functionality of this and many other
          websites that you visit. Disabling cookies will usually result in also
          disabling certain functionality and features of the this site.
          Therefore it is recommended that you do not disable cookies. This
          Cookies Policy was created with the help of the{" "}
          <a href="https://www.cookiepolicygenerator.com/cookie-policy-generator/">
            Cookies Policy Generator from CookiePolicyGenerator.com
          </a>
          .
        </p>
        <p>
          <strong>The Cookies We Set</strong>
        </p>
        <ul>
          <li>
            <p>Account related cookies</p>
            <p>
              If you create an account with us then we will use cookies for the
              management of the signup process and general administration. These
              cookies will usually be deleted when you log out however in some
              cases they may remain afterwards to remember your site preferences
              when logged out.
            </p>
          </li>

          <li>
            <p>Login related cookies</p>
            <p>
              We use cookies when you are logged in so that we can remember this
              fact. This prevents you from having to log in every single time
              you visit a new page. These cookies are typically removed or
              cleared when you log out to ensure that you can only access
              restricted features and areas when logged in.
            </p>
          </li>
          <li>
            <p>Site preferences cookies</p>
            <p>
              In order to provide you with a great experience on this site we
              provide the functionality to set your preferences for how this
              site runs when you use it. In order to remember your preferences
              we need to set cookies so that this information can be called
              whenever you interact with a page is affected by your preferences.
            </p>
          </li>
        </ul>
        <p>
          <strong>Third Party Cookies</strong>
        </p>
        <p>
          In some special cases we also use cookies provided by trusted third
          parties. The following section details which third party cookies you
          might encounter through this site.
        </p>
        <ul>
          <li>
            <p>
              This site uses Google Analytics which is one of the most
              widespread and trusted analytics solution on the web for helping
              us to understand how you use the site and ways that we can improve
              your experience. These cookies may track things such as how long
              you spend on the site and the pages that you visit so we can
              continue to produce engaging content.
            </p>
            <p>
              For more information on Google Analytics cookies, see the official
              Google Analytics page.
            </p>
          </li>

          <li>
            <p>
              Third party analytics are used to track and measure usage of this
              site so that we can continue to produce engaging content. These
              cookies may track things such as how long you spend on the site or
              pages you visit which helps us to understand how we can improve
              the site for you.
            </p>
          </li>

          <li>
            <p>
              From time to time we test new features and make subtle changes to
              the way that the site is delivered. When we are still testing new
              features these cookies may be used to ensure that you receive a
              consistent experience whilst on the site whilst ensuring we
              understand which optimisations our users appreciate the most.
            </p>
          </li>

          <li>
            <p>
              The Google AdSense service we use to serve advertising uses a
              DoubleClick cookie to serve more relevant ads across the web and
              limit the number of times that a given ad is shown to you.
            </p>
            <p>
              For more information on Google AdSense see the official Google
              AdSense privacy FAQ.
            </p>
          </li>

          <li>
            <p>
              We use adverts to offset the costs of running this site and
              provide funding for further development. The behavioural
              advertising cookies used by this site are designed to ensure that
              we provide you with the most relevant adverts where possible by
              anonymously tracking your interests and presenting similar things
              that may be of interest.
            </p>
          </li>

          <li>
            <p>
              We also use social media buttons and/or plugins on this site that
              allow you to connect with your social network in various ways. For
              these to work the following social media sites including; Disqus,
              will set cookies through our site which may be used to enhance
              your profile on their site or contribute to the data they hold for
              various purposes outlined in their respective privacy policies.
            </p>
          </li>
        </ul>
        <p>
          <strong>More Information</strong>
        </p>
        <p>
          Hopefully that has clarified things for you and as was previously
          mentioned if there is something that you aren't sure whether you need
          or not it's usually safer to leave cookies enabled in case it does
          interact with one of the features you use on our site.
        </p>
        <p>
          However if you are still looking for more information then you can
          contact us through one of our preferred contact methods:
        </p>
        <ul>
          <li>Email: gdpr@clintextgen.io</li>
        </ul>
        <GDPRSettingsPanel renderAsPanel={true} />
      </Container>
    );
  }
}

export class PrivacyPolicy extends Component {
  render() {
    return (
      <Container className="p-pt-5">
        <h1>Privacy Policy for ClinTextGen.io</h1>

        <p>
          At ClinTextGen, accessible from https://www.clintextgen.io, one of our
          main priorities is the privacy of our visitors. This Privacy Policy
          document contains types of information that is collected and recorded
          by ClinTextGen and how we use it.
        </p>

        <p>
          If you have additional questions or require more information about our
          Privacy Policy, do not hesitate to contact us.
        </p>

        <p>
          This Privacy Policy applies only to our online activities and is valid
          for visitors to our website with regards to the information that they
          shared and/or collect in ClinTextGen. This policy is not applicable to
          any information collected offline or via channels other than this
          website. Our Privacy Policy was created with the help of the{" "}
          <a href="https://www.cookiepolicygenerator.com/privacy-policy-generator/">
            Privacy Policy Generator from CookiePolicyGenerator.com
          </a>
          .
        </p>

        <h2>Consent</h2>

        <p>
          By using our website, you hereby consent to our Privacy Policy and
          agree to its terms.
        </p>

        <h2>Information we collect</h2>

        <p>
          The personal information that you are asked to provide, and the
          reasons why you are asked to provide it, will be made clear to you at
          the point we ask you to provide your personal information.
        </p>
        <p>
          If you contact us directly, we may receive additional information
          about you such as your name, email address, phone number, the contents
          of the message and/or attachments you may send us, and any other
          information you may choose to provide.
        </p>
        <p>
          When you register for an Account, we may ask for your contact
          information, including items such as name, company name, address,
          email address, and telephone number.
        </p>

        <h2>How we use your information</h2>

        <p>We use the information we collect in various ways, including to:</p>

        <ul>
          <li>Provide, operate, and maintain our webste</li>
          <li>Improve, personalize, and expand our webste</li>
          <li>Understand and analyze how you use our webste</li>
          <li>Develop new products, services, features, and functionality</li>
          <li>
            Communicate with you, either directly or through one of our
            partners, including for customer service, to provide you with
            updates and other information relating to the webste, and for
            marketing and promotional purposes
          </li>
          <li>Send you emails</li>
          <li>Find and prevent fraud</li>
        </ul>

        <h2>Log Files</h2>

        <p>
          ClinTextGen follows a standard procedure of using log files. These
          files log visitors when they visit websites. All hosting companies do
          this and a part of hosting services' analytics. The information
          collected by log files include internet protocol (IP) addresses,
          browser type, Internet Service Provider (ISP), date and time stamp,
          referring/exit pages, and possibly the number of clicks. These are not
          linked to any information that is personally identifiable. The purpose
          of the information is for analyzing trends, administering the site,
          tracking users' movement on the website, and gathering demographic
          information.
        </p>

        <h2>Cookies and Web Beacons</h2>

        <p>
          Like any other website, ClinTextGen uses 'cookies'. These cookies are
          used to store information including visitors' preferences, and the
          pages on the website that the visitor accessed or visited. The
          information is used to optimize the users' experience by customizing
          our web page content based on visitors' browser type and/or other
          information.
        </p>

        <p>
          For more general information on cookies, please read{" "}
          <a href="https://www.cookieconsent.com/what-are-cookies/">
            "What Are Cookies"
          </a>
          . Information regarding cookies from this Cookies Policy are from{" "}
          <a href="https://www.generateprivacypolicy.com/">
            the Privacy Policy Generator
          </a>
          .
        </p>

        <h2>Google DoubleClick DART Cookie</h2>

        <p>
          Google is one of a third-party vendor on our site. It also uses
          cookies, known as DART cookies, to serve ads to our site visitors
          based upon their visit to www.website.com and other sites on the
          internet. However, visitors may choose to decline the use of DART
          cookies by visiting the Google ad and content network Privacy Policy
          at the following URL –{" "}
          <a href="https://policies.google.com/technologies/ads">
            https://policies.google.com/technologies/ads
          </a>
        </p>

        <h2>Our Advertising Partners</h2>

        <p>
          Some of advertisers on our site may use cookies and web beacons. Our
          advertising partners are listed below. Each of our advertising
          partners has their own Privacy Policy for their policies on user data.
          For easier access, we hyperlinked to their Privacy Policies below.
        </p>

        <ul>
          <li>
            <p>Google</p>
            <p>
              <a href="https://policies.google.com/technologies/ads">
                https://policies.google.com/technologies/ads
              </a>
            </p>
          </li>
        </ul>

        <h2>Advertising Partners Privacy Policies</h2>

        <p>
          You may consult this list to find the Privacy Policy for each of the
          advertising partners of ClinTextGen.
        </p>

        <p>
          Third-party ad servers or ad networks uses technologies like cookies,
          JavaScript, or Web Beacons that are used in their respective
          advertisements and links that appear on ClinTextGen, which are sent
          directly to users' browser. They automatically receive your IP address
          when this occurs. These technologies are used to measure the
          effectiveness of their advertising campaigns and/or to personalize the
          advertising content that you see on websites that you visit.
        </p>

        <p>
          Note that ClinTextGen has no access to or control over these cookies
          that are used by third-party advertisers.
        </p>

        <h2>Third Party Privacy Policies</h2>

        <p>
          ClinTextGen's Privacy Policy does not apply to other advertisers or
          websites. Thus, we are advising you to consult the respective Privacy
          Policies of these third-party ad servers for more detailed
          information. It may include their practices and instructions about how
          to opt-out of certain options.{" "}
        </p>

        <p>
          You can choose to disable cookies through your individual browser
          options. To know more detailed information about cookie management
          with specific web browsers, it can be found at the browsers'
          respective websites.
        </p>

        <h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>

        <p>
          Under the CCPA, among other rights, California consumers have the
          right to:
        </p>
        <p>
          Request that a business that collects a consumer's personal data
          disclose the categories and specific pieces of personal data that a
          business has collected about consumers.
        </p>
        <p>
          Request that a business delete any personal data about the consumer
          that a business has collected.
        </p>
        <p>
          Request that a business that sells a consumer's personal data, not
          sell the consumer's personal data.
        </p>
        <p>
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us.
        </p>

        <h2>GDPR Data Protection Rights</h2>

        <p>
          We would like to make sure you are fully aware of all of your data
          protection rights. Every user is entitled to the following:
        </p>
        <p>
          The right to access – You have the right to request copies of your
          personal data. We may charge you a small fee for this service.
        </p>
        <p>
          The right to rectification – You have the right to request that we
          correct any information you believe is inaccurate. You also have the
          right to request that we complete the information you believe is
          incomplete.
        </p>
        <p>
          The right to erasure – You have the right to request that we erase
          your personal data, under certain conditions.
        </p>
        <p>
          The right to restrict processing – You have the right to request that
          we restrict the processing of your personal data, under certain
          conditions.
        </p>
        <p>
          The right to object to processing – You have the right to object to
          our processing of your personal data, under certain conditions.
        </p>
        <p>
          The right to data portability – You have the right to request that we
          transfer the data that we have collected to another organization, or
          directly to you, under certain conditions.
        </p>
        <p>
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us.
        </p>

        <h2>Children's Information</h2>

        <p>
          Another part of our priority is adding protection for children while
          using the internet. We encourage parents and guardians to observe,
          participate in, and/or monitor and guide their online activity.
        </p>

        <p>
          ClinTextGen does not knowingly collect any Personal Identifiable
          Information from children under the age of 13. If you think that your
          child provided this kind of information on our website, we strongly
          encourage you to contact us immediately and we will do our best
          efforts to promptly remove such information from our records.
        </p>
      </Container>
    );
  }
}

export class TermsAndConditions extends Component {
  render() {
    return (
      <Container className="p-pt-5">
        <h2>
          <strong>Terms and Conditions</strong>
        </h2>

        <p>Welcome to ClinTextGen!</p>

        <p>
          These terms and conditions outline the rules and regulations for the
          use of ClinTextGen.io's Website, located at
          https://www.clintextgen.io.
        </p>

        <p>
          By accessing this website we assume you accept these terms and
          conditions. Do not continue to use ClinTextGen if you do not agree to
          take all of the terms and conditions stated on this page.
        </p>

        <p>
          The following terminology applies to these Terms and Conditions,
          Privacy Statement and Disclaimer Notice and all Agreements: "Client",
          "You" and "Your" refers to you, the person log on this website and
          compliant to the Company’s terms and conditions. "The Company",
          "Ourselves", "We", "Our" and "Us", refers to our Company. "Party",
          "Parties", or "Us", refers to both the Client and ourselves. All terms
          refer to the offer, acceptance and consideration of payment necessary
          to undertake the process of our assistance to the Client in the most
          appropriate manner for the express purpose of meeting the Client’s
          needs in respect of provision of the Company’s stated services, in
          accordance with and subject to, prevailing law of Belgium. Any use of
          the above terminology or other words in the singular, plural,
          capitalization and/or he/she or they, are taken as interchangeable and
          therefore as referring to same.
        </p>

        <h3>
          <strong>Cookies</strong>
        </h3>

        <p>
          We employ the use of cookies. By accessing ClinTextGen, you agreed to
          use cookies in agreement with the ClinTextGen.io's Privacy Policy.{" "}
        </p>

        <p>
          Most interactive websites use cookies to let us retrieve the user’s
          details for each visit. Cookies are used by our website to enable the
          functionality of certain areas to make it easier for people visiting
          our website. Some of our affiliate/advertising partners may also use
          cookies.
        </p>

        <h3>
          <strong>License</strong>
        </h3>

        <p>
          Unless otherwise stated, ClinTextGen.io and/or its licensors own the
          intellectual property rights for all material on ClinTextGen. All
          intellectual property rights are reserved. You may access this from
          ClinTextGen for your own personal use subjected to restrictions set in
          these terms and conditions.
        </p>
        <p>
          By providing content to us, you grant to ClinTextGen.io a worldwide,
          non-exclusive, royalty-free, transferable, sublicensable licence to
          use that content (including to reproduce, distribute, modify, display
          and perform it) for the purpose of operating, promoting, and improving
          ClinTextGen.io. You also grant each user of ClinTextGen.io a
          worldwide, non-exclusive, royalty-free licence to access the content
          you have added through the service, and to use that content (including
          to reproduce, distribute, modify, display, and perform it) as enabled
          by a feature of ClinTextGen.io.
        </p>

        <p>You must not:</p>
        <ul>
          <li>
            Republish material that is not an intended result of using
            ClinTextGen (e.g. App or Gallery) without prior written consent
          </li>
          <li>Sell, rent or sub-license any material from ClinTextGen</li>
          <li>
            Reproduce, duplicate or copy material from ClinTextGen that is not a
            result of using the service (e.g. App).
          </li>
          <li>Redistribute content from ClinTextGen</li>
          <li>
            Add content to ClinTextGen that you do not own the rights therefore.
          </li>
        </ul>

        <p>
          This Agreement shall begin on the date November 28th 2020. Our Terms
          and Conditions were created with the help of the{" "}
          <a href="https://www.termsandconditionsgenerator.com">
            Terms And Conditions Generator
          </a>{" "}
          and the{" "}
          <a href="https://www.generateprivacypolicy.com">
            Privacy Policy Generator
          </a>
          .
        </p>

        <p>
          Parts of this website offer an opportunity for users to post and
          exchange opinions and information in certain areas of the website.
          ClinTextGen.io does not filter, edit, publish or review Comments prior
          to their presence on the website. Comments do not reflect the views
          and opinions of ClinTextGen.io,its agents and/or affiliates. Comments
          reflect the views and opinions of the person who post their views and
          opinions. To the extent permitted by applicable laws, ClinTextGen.io
          shall not be liable for the Comments or for any liability, damages or
          expenses caused and/or suffered as a result of any use of and/or
          posting of and/or appearance of the Comments on this website.
        </p>

        <p>
          ClinTextGen.io reserves the right to monitor all Comments and to
          remove any Comments which can be considered inappropriate, offensive
          or causes breach of these Terms and Conditions.
        </p>

        <p>You warrant and represent that:</p>

        <ul>
          <li>
            You are entitled to post the Comments on our website and have all
            necessary licenses and consents to do so;
          </li>
          <li>
            The Comments do not invade any intellectual property right,
            including without limitation copyright, patent or trademark of any
            third party;
          </li>
          <li>
            The Comments do not contain any defamatory, libelous, offensive,
            indecent or otherwise unlawful material which is an invasion of
            privacy
          </li>
          <li>
            The Comments will not be used to solicit or promote business or
            custom or present commercial activities or unlawful activity.
          </li>
        </ul>

        <p>
          You hereby grant ClinTextGen.io a non-exclusive license to use,
          reproduce, edit and authorize others to use, reproduce and edit any of
          your Comments in any and all forms, formats or media.
        </p>

        <h3>
          <strong>Hyperlinking to our Content</strong>
        </h3>

        <p>
          The following organizations may link to our Website without prior
          written approval:
        </p>

        <ul>
          <li>Government agencies;</li>
          <li>Search engines;</li>
          <li>News organizations;</li>
          <li>
            Online directory distributors may link to our Website in the same
            manner as they hyperlink to the Websites of other listed businesses;
            and
          </li>
          <li>
            System wide Accredited Businesses except soliciting non-profit
            organizations, charity shopping malls, and charity fundraising
            groups which may not hyperlink to our Web site.
          </li>
        </ul>

        <p>
          These organizations may link to our home page, to publications or to
          other Website information so long as the link: (a) is not in any way
          deceptive; (b) does not falsely imply sponsorship, endorsement or
          approval of the linking party and its products and/or services; and
          (c) fits within the context of the linking party’s site.
        </p>

        <p>
          We may consider and approve other link requests from the following
          types of organizations:
        </p>

        <ul>
          <li>commonly-known consumer and/or business information sources;</li>
          <li>dot.com community sites;</li>
          <li>associations or other groups representing charities;</li>
          <li>online directory distributors;</li>
          <li>internet portals;</li>
          <li>accounting, law and consulting firms; and</li>
          <li>educational institutions and trade associations.</li>
        </ul>

        <p>
          We will approve link requests from these organizations if we decide
          that: (a) the link would not make us look unfavorably to ourselves or
          to our accredited businesses; (b) the organization does not have any
          negative records with us; (c) the benefit to us from the visibility of
          the hyperlink compensates the absence of ClinTextGen.io; and (d) the
          link is in the context of general resource information.
        </p>

        <p>
          These organizations may link to our home page so long as the link: (a)
          is not in any way deceptive; (b) does not falsely imply sponsorship,
          endorsement or approval of the linking party and its products or
          services; and (c) fits within the context of the linking party’s site.
        </p>

        <p>
          If you are one of the organizations listed in paragraph 2 above and
          are interested in linking to our website, you must inform us by
          sending an e-mail to ClinTextGen.io. Please include your name, your
          organization name, contact information as well as the URL of your
          site, a list of any URLs from which you intend to link to our Website,
          and a list of the URLs on our site to which you would like to link.
          Wait 2-3 weeks for a response.
        </p>

        <p>Approved organizations may hyperlink to our Website as follows:</p>

        <ul>
          <li>By use of our corporate name; or</li>
          <li>By use of the uniform resource locator being linked to; or</li>
          <li>
            By use of any other description of our Website being linked to that
            makes sense within the context and format of content on the linking
            party’s site.
          </li>
        </ul>

        <p>
          No use of ClinTextGen.io's logo or other artwork will be allowed for
          linking absent a trademark license agreement.
        </p>

        <h3>
          <strong>iFrames</strong>
        </h3>

        <p>
          Without prior approval and written permission, you may not create
          frames around our Webpages that alter in any way the visual
          presentation or appearance of our Website.
        </p>

        <h3>
          <strong>Content Liability</strong>
        </h3>

        <p>
          We shall not be hold responsible for any content that appears on your
          Website. You agree to protect and defend us against all claims that is
          rising on your Website. No link(s) should appear on any Website that
          may be interpreted as libelous, obscene or criminal, or which
          infringes, otherwise violates, or advocates the infringement or other
          violation of, any third party rights.
        </p>

        <h3>
          <strong>Your Privacy</strong>
        </h3>

        <p>Please read Privacy Policy</p>

        <h3>
          <strong>Reservation of Rights</strong>
        </h3>

        <p>
          We reserve the right to request that you remove all links or any
          particular link to our Website. You approve to immediately remove all
          links to our Website upon request. We also reserve the right to amen
          these terms and conditions and it’s linking policy at any time. By
          continuously linking to our Website, you agree to be bound to and
          follow these linking terms and conditions.
        </p>

        <h3>
          <strong>Removal of links from our website</strong>
        </h3>

        <p>
          If you find any link on our Website that is offensive for any reason,
          you are free to contact and inform us any moment. We will consider
          requests to remove links but we are not obligated to or so or to
          respond to you directly.
        </p>

        <p>
          We do not ensure that the information on this website is correct, we
          do not warrant its completeness or accuracy; nor do we promise to
          ensure that the website remains available or that the material on the
          website is kept up to date.
        </p>

        <h3>
          <strong>Disclaimer</strong>
        </h3>

        <p>
          To the maximum extent permitted by applicable law, we exclude all
          representations, warranties and conditions relating to our website and
          the use of this website. Nothing in this disclaimer will:
        </p>

        <ul>
          <li>
            limit or exclude our or your liability for death or personal injury;
          </li>
          <li>
            limit or exclude our or your liability for fraud or fraudulent
            misrepresentation;
          </li>
          <li>
            limit any of our or your liabilities in any way that is not
            permitted under applicable law; or
          </li>
          <li>
            exclude any of our or your liabilities that may not be excluded
            under applicable law.
          </li>
        </ul>

        <p>
          The limitations and prohibitions of liability set in this Section and
          elsewhere in this disclaimer: (a) are subject to the preceding
          paragraph; and (b) govern all liabilities arising under the
          disclaimer, including liabilities arising in contract, in tort and for
          breach of statutory duty.
        </p>

        <p>
          As long as the website and the information and services on the website
          are provided free of charge, we will not be liable for any loss or
          damage of any nature.
        </p>
      </Container>
    );
  }
}
