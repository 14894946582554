import React, { Component } from "react";
import PropTypes from "prop-types";

import { Chips } from "primereact/chips";
import AutoresizeInputText from "./autoresizeInputText";
import AutoresizeDropDown from "./autoresizeDropdown";
import { Inplace, InplaceDisplay, InplaceContent } from "primereact/inplace";
import { MultiSelect } from "primereact/multiselect";
import { TriStateCheckbox } from "primereact/tristatecheckbox";

class Finding extends Component {
  //krijgt zijn string van de findingsGroup en geeft in functie daarvan weer
  //heeft onclick, save en

  state = {
    inplace_active: false, //inplace is being edited
    id: null,
    type: null,
    findingString: "",
    //options: [], //optional
    //placeholder: null, //optionnal
    value: null,
    hasPlaceholder: null,
  };

  static propTypes = {
    finding: PropTypes.object,
    onContextMenu: PropTypes.func,
    showValueInTooltip: PropTypes.bool, //used in the displaypanel
  };

  activateInputDisplay(event) {
    this.inputDisplay.current.open(event);
  }

  //construct from string
  constructor(props) {
    super(props);
    this.state.cb_value = null;

    this.inputDisplay = React.createRef();
    this.activateInputDisplay = this.activateInputDisplay.bind(this);
    this.getCheckboxValue = this.getCheckboxValue.bind(this);
    this.onDropdownChange = this.onDropdownChange.bind(this);
    this.onTextboxChange = this.onTextboxChange.bind(this);
    this.renderInplace = this.renderInplace.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    //console.log(e);
    this.props.onChange(this.props.finding.id, e.value);
  }

  onCheckboxChange(e) {
    //console.log(e);
    let { finding } = this.props;

    let newValue = "";
    if (e.value === false) {
      newValue = finding.false_text;
    }

    if (e.value === true) {
      newValue = finding.true_text;
    }
    this.setState({ cb_value: e.value });
    this.props.onChange(finding.id, newValue);
  }

  onTextboxChange(e) {
    this.props.onChange(this.props.finding.id, e.target.value);
  }

  onDropdownChange(e) {
    //console.log(e);
    this.props.onChange(this.props.finding.id, e.value);
  }

  onMultiSelectChange(e) {
    this.props.onChange(this.props.finding.id, e.value);
  }

  onNewMultiSelectChange(e) {
    //console.log(e);
    //Reduce to arary with only label values
    e.value.map((item) => {
      return item.label;
    });

    this.props.onChange(
      this.props.finding.id,
      e.value.map((item) => {
        return item.label;
      })
    );
  }

  renderInplace(formatted_finding) {
    let { finding } = this.props; //import this.props.finding as finding

    //small helperfunction to format multiselect values in the render method.
    let getFormattedValue = (finding) => {
      let returnValue = "";
      switch (finding.type) {
        case "multiselect":
          returnValue = finding.value.join(", ");
          break;
        case "checkbox":
          returnValue = finding.placeholder;
          break;
        default:
          returnValue = finding.value;
          break;
      }
      return returnValue;
    };

    return (
      <div onContextMenu={this.props.onContextMenu}>
        <Inplace
          closable
          active={this.state.inplace_active}
          onToggle={(e) => this.setState({ inplace_active: e.value })}
          className={
            finding.type === "helpertext" ? "" : "border-bottom border-warning"
          }
          ref={this.inputDisplay} //this is needed to act as a functional opener.
        >
          <InplaceDisplay>
            {finding.value ? (
              getFormattedValue(finding)
            ) : (
              <span className="p-text-light">{finding.placeholder} </span>
            )}
          </InplaceDisplay>

          <InplaceContent>{formatted_finding}</InplaceContent>
        </Inplace>
      </div>
    );
  }
  render() {
    var formatted_finding;
    let { finding } = this.props; //import this.props.finding as finding

    // get formatted component JSX for the type of finding
    switch (finding.type) {
      case "textbox":
        formatted_finding = this.formatTextBox();
        break;
      case "checkbox":
        formatted_finding = this.formatCheckBox();
        break;
      case "multiselect":
        formatted_finding = this.formatMultiSelect();
        break;
      case "dropdown":
        formatted_finding = this.formatDropDown();
        break;
      case "helpertext":
        formatted_finding = this.formatTextBox();
        break;
      default:
        break;
    }

    //render textbox as such, render the others as inplace
    return finding.type === "checkbox"
      ? this.formatCheckBox()
      : this.renderInplace(formatted_finding);
  }

  formatTextBox() {
    let { finding } = this.props; //import this.props.finding as finding
    return (
      <AutoresizeInputText
        autoFocus
        className="p-pr-1"
        value={finding.value}
        id={this.state.id}
        autoResize={true}
        onChange={(e) => this.onTextboxChange(e)}
        placeholder={finding.placeholder}
      />
    );
  }

  formatCheckBox() {
    //console.log(this.state.cb_value);
    let tooltip =
      this.props.showValueInTooltip || false ? this.props.finding.value : "";
    return (
      <div className="p-field-checkbox p-mb-0">
        <TriStateCheckbox
          id={this.state.id}
          onChange={(e) => this.onCheckboxChange(e)}
          value={this.state.cb_value}
          className="p-mr-1"
          tooltip={tooltip}
        />
        {this.props.finding.placeholder}
      </div>
    );
  }

  getCheckboxValue() {
    let { finding } = this.props; //import this.props.finding as finding
    let displayText = "";
    switch (this.state.cb_value) {
      case true:
        displayText = finding.true_text;
        break;
      case false:
        displayText = finding.false_text;
        break;
      case null:
        displayText = finding.placeholder;
        break;
      default:
        break;
    }

    return displayText;
  }

  formatMultiSelect() {
    let { finding } = this.props; //import this.props.finding as finding
    //console.log(finding);
    return (
      <Chips
        id={this.state.id}
        value={finding.value}
        style={{ display: "inline-block", maxWidth: "90%" }}
        placeholder={finding.hasPlaceholder ? finding.placeholder : ""}
        onChange={(e) => this.onMultiSelectChange(e)}
      />
    );
  }

  newformatMultiSelect() {
    let { finding } = this.props; //import this.props.finding as finding

    //add label option
    let optionList = finding.options.map((item) => {
      return { label: item };
    });

    let valueList = finding.value.map((item) => {
      return { label: item };
    });

    //console.log(finding);
    return (
      <MultiSelect
        id={this.state.id}
        value={valueList}
        options={optionList}
        placeholder={finding.hasPlaceholder ? finding.placeholder : ""}
        filter={true}
        onChange={(e) => this.onMultiSelectChange(e)}
      />
    );
  }

  formatDropDown() {
    let { finding } = this.props; //import this.props.finding as finding

    return (
      <AutoresizeDropDown
        value={finding.value}
        options={finding.options}
        editable={true}
        placeholder={finding.hasPlaceholder ? finding.placeholder : ""}
        onChange={(e) => this.onDropdownChange(e)}
      />
    );
  }
}

export default Finding;
