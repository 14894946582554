import * as actions from "../actions/types";
import produce from "immer";

export const initialGalleryState = {
  clindocs: [],
  loading: false,
  error: null,
  selectedClinDoc: {},

  //pagination
};
export function galleryReducer(state = initialGalleryState, action) {
  const { payload } = action;

  switch (action.type) {
    case actions.GALLERY_CLINDOC_DETAILS_CHANGE:
      return produce(state, (draftState) => {
        draftState.selectedClinDoc[payload.fieldName] = payload.value;
      });

    case actions.GALLERY_NEW_CLINDOC:
      return {
        ...state,
        selectedClinDoc: {
          name: "",
          description: "",
          id: 0,
          inputString: "",
        },
      };

    //fetch clindocs
    case actions.GALLERY_FETCH_CLINDOCS_LOAD:
      return { ...state, loading: true };

    case actions.GALLERY_FETCH_CLINDOCS_SUCCESS:
      return produce(state, (draftState) => {
        draftState.clindocs = payload.clindocs;
        draftState.loading = false;
      });

    case actions.GALLERY_FETCH_CLINDOCS_ERROR:
      return produce(state, (draftState) => {
        draftState.error = payload.error;
        draftState.loading = false;
      });

    //fetch details
    case actions.GALLERY_FETCH_CLINDOC_DETAILS_LOAD:
      return { ...state, loading: true };

    case actions.GALLERY_FETCH_CLINDOC_DETAILS_SUCCESS:
      return produce(state, (draftState) => {
        draftState.selectedClinDoc = payload.document;
        //draftState.selectedClinDoc.findings = helpers.createFindingsArrayFromString(
        //  payload.document.inputString
        //);
        draftState.loading = false;
      });

    case actions.GALLERY_FETCH_CLINDOC_DETAILS_ERROR:
      return produce(state, (draftState) => {
        draftState.error = payload.error;
        draftState.loading = false;
      });

    //save clindoc
    case actions.GALLERY_SAVE_CLINDOC_LOAD:
      return { ...state, loading: true };

    case actions.GALLERY_SAVE_CLINDOC_SUCCESS:
      return produce(state, (draftState) => {
        const newState = state.selectedClinDoc.id === 0;

        //if index == 0 (by convention this means new):
        if (newState) {
          //save as new
          draftState.clindocs.push(payload);
        } else {
          //update
          const index = draftState.clindocs.findIndex(
            (c) => c.id === payload.id
          );
          draftState.clindocs[index] = payload;
        }
        draftState.loading = false;
      });

    case actions.GALLERY_SAVE_CLINDOC_ERROR:
      return produce(state, (draftState) => {
        draftState.error = payload.error;
        draftState.loading = false;
      });

    //delete clindoc
    case actions.GALLERY_DELETE_CLINDOC_LOAD:
      return { ...state, loading: true };

    case actions.GALLERY_DELETE_CLINDOC_SUCCESS:
      return produce(state, (draftState) => {
        const index = draftState.clindocs.findIndex((c) => c.id === payload.id);
        if (index !== -1) draftState.clindocs.splice(index, 1);
        draftState.loading = false;
      });

    case actions.GALLERY_DELETE_CLINDOC_ERROR:
      return produce(state, (draftState) => {
        draftState.error = payload.error;
        draftState.loading = false;
      });

    //import
    case actions.GALLERY_IMPORT_CLINDOC_LOAD:
      return { ...state, loading: true };

    case actions.GALLERY_IMPORT_CLINDOC_SUCCESS:
      return { ...state, loading: false };

    case actions.GALLERY_IMPORT_CLINDOC_ERROR:
      return { ...state, loading: false };

    //ratings
    case actions.GALLERY_CHANGE_CLINDOC_RATING_LOAD:
      return { ...state };

    case actions.GALLERY_CHANGE_CLINDOC_RATING_SUCCESS:
      return produce(state, (draftState) => {
        draftState.selectedClinDoc.rating = payload.rating;
        draftState.selectedClinDoc.rating_id = payload.id;
        //draftState.loading = false;
      });

    case actions.GALLERY_CHANGE_CLINDOC_RATING_ERROR:
      return produce(state, (draftState) => {
        draftState.error = payload.error;
        //draftState.loading = false;
      });

    default:
      return state;
  }
}
