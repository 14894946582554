import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_UPDATE_LOADING,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_ERROR,
  PROFILE_UPDATE_LOADING,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_ERROR,
  CHANGE_PASSWORD_LOADING,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
} from "../actions/types";

export const initialAuthState = {
  isAuthenticated: null,
  token: localStorage.getItem("token"),
  user: null,
  isLoading: false,
  errors: {},
};

export function authReducer(state = initialAuthState, action) {
  //profile update
  switch (action.type) {
    //change password does not update reducer state at this moment.
    case CHANGE_PASSWORD_LOADING:
      return state;
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        errors: {
          ...state.errors,
          old_password: [],
          new_password: [],
        },
      };
    case CHANGE_PASSWORD_ERROR:
      return { ...state, errors: action.payload.errors };

    //profile update
    case PROFILE_UPDATE_LOADING:
      return { ...state };

    case PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          profile: action.payload.data,
        },
      };
    case PROFILE_UPDATE_ERROR:
      return { ...state, errors: action.payload.error };

    //user update
    case USER_UPDATE_LOADING:
      return {
        ...state,
      };

    case USER_UPDATE_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        user: action.payload,
      };

    case USER_UPDATE_ERROR:
      return { ...state, errors: action.payload.error };

    //auth
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case USER_LOADED:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        user: action.payload,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        isAuthenticated: true,
      };
    case LOGOUT:
      localStorage.removeItem("token");
      return {
        ...state,
        isLoading: false,
        user: null,
        isAuthenticated: false,
        token: null,
      };

    //register
    case REGISTER_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        user: action.payload,
        token: action.payload.token,
      };

    case REGISTER_FAIL:
      localStorage.removeItem("token");
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        token: null,
        user: null,
        errors: action.payload.errors,
      };

    case AUTH_ERROR:
    case LOGIN_FAIL:
      const payload = action.payload || {};
      const errors = payload.errors || {};
      return { ...state, errors: errors };

    default:
      return state;
  }
}
