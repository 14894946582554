import * as actions from "../actions/types";
import produce from "immer";

export const initialClinDocEditorState = {
  editorUnsavedChanges: false,
  currentEditorClinDoc: {
    id: 0,
    name: "",
    description: "",
    inputString: "",
  },
  error: null,
};

//set initial state to empty array in order to avoid returning undefined.
//reducer needs to be pure function (same result every time it is called for same argument)
export function clinDocEditorReducer(
  state = initialClinDocEditorState,
  action
) {
  const { payload } = action;
  //console.log(action);

  switch (action.type) {
    //Saving a clindoc
    case actions.SAVE_CLINDOC_LOAD:
      return produce(state, (draftState) => {
        //draftState.loading = true;
      });

    case actions.SAVE_CLINDOC_ERROR:
      return produce(state, (draftState) => {
        draftState.error = payload.error;
        //draftState.loading = false;
      });

    case actions.SAVE_CLINDOC_SUCCESS:
      return produce(state, (draftState) => {
        //draftState.loading = false;
        draftState.currentEditorClinDoc.id = payload.id;
        draftState.editorUnsavedChanges = false;
      });

    //deleting a clindoc
    case actions.DELETE_CLINDOC_LOAD:
      return produce(state, (draftState) => {
        //draftState.loading = true;
      });

    case actions.DELETE_CLINDOC_ERROR:
      return produce(state, (draftState) => {
        draftState.error = payload.error;
        //draftState.loading = false;
      });

    case actions.DELETE_CLINDOC_SUCCESS:
      return produce(state, (draftState) => {
        // draftState.loading = false;
        draftState.currentEditorClinDocID = 0;
      });

    //update editor id
    case actions.CHANGE_EDITOR_CLINDOC:
      return {
        ...state,
        currentEditorClinDoc: payload.document,
        editorUnsavedChanges: false,
      };

    case actions.CHANGE_EDITOR_NAME:
      return produce(state, (draftState) => {
        draftState.currentEditorClinDoc.name = payload.value;
      });

    case actions.CHANGE_EDITOR_DESCRIPTION:
      return produce(state, (draftState) => {
        draftState.currentEditorClinDoc.description = payload.value;
      });

    case actions.CHANGE_EDITOR_STRING:
      return produce(state, (draftState) => {
        draftState.currentEditorClinDoc.inputString = payload.value;
      });

    case actions.NEW_EDITOR_CLINDOC:
      return {
        ...state,
        currentEditorClinDoc: {
          id: 0,
          inputString: "",
          name: "",
          description: "",
        },
        editorUnsavedChanges: false,
      };

    case actions.CHANGE_EDITOR_ID:
      return produce(state, (draftState) => {
        draftState.currentEditorClinDoc.id = payload.value;
      });

    case actions.EDITOR_CLINDOC_CHANGE_UNSAVED_CHANGES:
      return produce(state, (draftState) => {
        draftState.editorUnsavedChanges = payload.value;
      });

    /*
    case actions.START_LOADING:
      return { ...state, loading: true };

    case actions.STOP_LOADING:
      return { ...state, loading: false };
      */

    default:
      return state;
  }
}
