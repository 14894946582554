import { createStore, combineReducers, applyMiddleware } from "redux";
import { clinDocReducer, initialClinDocState } from "./reducers/clindoc";
import { authReducer, initialAuthState } from "./reducers/auth";
import { uIReducer, initialUIState } from "./reducers/ui";
import { galleryReducer, initialGalleryState } from "./reducers/gallery";
import {
  clinDocEditorReducer,
  initialClinDocEditorState,
} from "./reducers/editor";

import thunk from "redux-thunk";
import undoable, {
  //includeAction,
  excludeAction,
  //groupByActionTypes,
} from "redux-undo";

//react-redux-spinner
import {
  pendingTasksReducer, // The redux reducer
} from "react-redux-spinner";
import {
  FETCH_CLINDOCS_ERROR,
  FETCH_CLINDOCS_LOAD,
  FETCH_CLINDOCS_SUCCESS,
  //CHANGE_VALUE,
} from "./actions/types";

const initialState = {
  auth: initialAuthState,
  clinDocState: initialClinDocState,
  clinDocEditorState: initialClinDocEditorState,
  uIState: initialUIState,
  galleryState: initialGalleryState,
};

/*
const client = axios.create({
  //all axios can be used, shown in axios documentation
  baseURL: "http://localhost:8000/api",
  responseType: "json",
});
*/

const middleware = [thunk]; //, axiosMiddleware(client)];

const reducers = combineReducers({
  clinDocState: undoable(clinDocReducer, {
    filter: excludeAction([
      FETCH_CLINDOCS_LOAD,
      FETCH_CLINDOCS_ERROR,
      FETCH_CLINDOCS_SUCCESS,
    ]),
  }),
  clinDocEditorState: clinDocEditorReducer,
  pendingTasks: pendingTasksReducer,
  auth: authReducer,
  uIState: uIReducer,
  galleryState: galleryReducer,
  //userState: userReducer,
  //widgetState: widgetReducer,
});

const store = createStore(
  reducers,
  initialState,
  applyMiddleware(...middleware)
);

//console.log(store.getState());
export default store;
