import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import { Accordion, AccordionTab } from "primereact/accordion";
import { DiscussionEmbed } from "disqus-react";

class About extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Container style={{ marginTop: "2rem" }}>
          <h1>About</h1>
          <p>
            ClinTextGen was created by an emergency physician during residency.
            It was created to quickly encode physical examination findings for
            use in various electronic medical records.
          </p>
          <p>
            The first iteration was very rudimentary, and dificult to add new
            documents to. This led to the addition of the editor and gallery
            function. The editor allows people to add elements without having to
            memorize the code.
          </p>
          <p>
            Since not everyone likes to code, a gallery was added where users
            can share their own documents with others, or import shared
            documents to their library.
          </p>
        </Container>
        <Container style={{ marginTop: "2rem" }}>
          <h2>FAQ</h2>
          <Accordion
            activeIndex={this.state.activeIndex}
            onTabChange={(e) => this.setState({ activeIndex: e.index })}
          >
            <AccordionTab header="How does ClinTextGen work?">
              ClinTextGen converts a descriptive string of to a set of html
              input elements. These elements and their contents then get
              reconverted to an output, which you can copy-paste in your
              prefered reporting application.
            </AccordionTab>
            <AccordionTab header="How do I add or edit a new document">
              In the Editor, you can open an existing document to edit, or click
              the new button to create a new document. Don't forget to save your
              changes!
            </AccordionTab>
            <AccordionTab header="How do I delete  document">
              Simply open the document you want to delete and press the delete
              button.
            </AccordionTab>
            <AccordionTab header="How do I import a document from the gallery">
              Search for the document you want to add, click the preview icon (
              <i className="pi pi-eye"></i>), and click the import button.
            </AccordionTab>
            <AccordionTab header="What are the codes used in the editor?">
              <p>Use the codes below.</p>
              <ul>
                <li>
                  Checkbox:{" "}
                  <code>
                    #CB|Description|Text added when checked|Text added when
                    unchecked#
                  </code>
                </li>
                <li>
                  Textbox:
                  <code>
                    #TB|Text<i>|placeholder="placeholder text"</i>#
                  </code>
                </li>
                <li>
                  Dropdown:{" "}
                  <code>
                    #DD|&#123;option 1; option 2; option 3;...&#125;|
                    <i>placeholder="placeholder text"</i>#
                  </code>
                </li>
                <li>
                  Multiselect:{" "}
                  <code>
                    #MS|&#123;option 1; option 2; option 3;...&#125;|
                    <i>placeholder="placeholder text"</i>#
                  </code>
                </li>
              </ul>
              <p>
                The italic placeholder option (
                <code>|placeholder="placeholder text"</code>) is optional.
              </p>
            </AccordionTab>
          </Accordion>
        </Container>
        <Container style={{ marginTop: "2rem" }}>
          <h2>Feedback</h2>
          <DiscussionEmbed
            shortname="clintextgen"
            config={{
              url: "clintextgen.io/about",
              identifier: "about",
              title: "Feedback page",
              language: "en", //e.g. for Traditional Chinese (Taiwan)
            }}
          />
        </Container>
      </React.Fragment>
    );
  }
}

export default About;
